.container{
    /* background-color: #F8F5FF; */
    
   /* font-family: 'ErasDemiITC'; */
}

.training h1  {
    text-align: center;
    font-family: 'ErasDemiITC';
    font-weight: bold;
    font-size: 40px;

}
.training p {
    padding: 20px 2rem 0px 2rem;
    text-align: center;
}
.btn-training{
  margin: 4rem 0px !important ;
}

.topic-section {
    width: 100%;
    // display: flex;
    // height: 500px;
    &__carousel {
        background-color: #fcfcfc;
        padding: 2rem 1.2rem 2px 2rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        width: 100%;

    }
    &__timer {
            width: 25%;
        background-color: #ffff;
        h1 {
            font-size: 25px !important;
            text-align: center;
        }
        span {
            color: #30a7e4;
            font-size: 25px !important;
        }
    }
}
.timer-zoom {
    display: flex;
    flex-wrap: wrap;
    // height: 590px;
    align-content: space-between;
        margin: 1rem 18px;

}

.carousel-control-next-icon {
    // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    background-color: #b0b0b0 !important;
    color: #fff;
    border-radius: 25px !important ;
    padding: 12px 12px !important ;
    width: 20px !important ;
    height: 20px !important ;
    position: absolute !important ;
   margin-right: 600px !important ;
}
.carousel-control-prev-icon {
    // background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e);
    background-color: #b0b0b0  !important ;
    border-radius: 25px !important ;
    padding: 12px 12px !important ;
    width: 20px !important ;
    height: 20px !important ;
    position: absolute !important ;
    left: 15px !important ;
}

.sliders {
    color: black;
    font-weight: bold;
    overflow-y: scroll;
    color: red;
}
.Scroll-bar {
    height: 500px;
    overflow-y: scroll;
}
.Page-content {
    text-align: center;
    margin: 20px 0px;
}

.Page-content p {
    text-align: center;
    font-weight: 900;
    font-family: fangsong;
    
}

.btn-trining {
    padding: 4px 25px;
    border-radius: 25px;
}

.previous-btn {
    margin-right: 10px;
    background-color: #30a7e4;
   
    color: white;

}

.finish-btn {
    margin-right: 10px;
    background-color: #e43039;
   
    color: white;

}

.pause-btn {
    margin-right: 20px;
    background-color: #0b5c55;
   
    color: rgb(17, 141, 135);

}

.next-btn {
    background-color: #30a7e4;
    color: #fff;
}
.next-btn:disabled, .previous-btn:disabled{
    background-color: #b0b0b0;
}
.p, ul, li, ol{
    font-family: "Calibri";
    font-size: "16px";
}
label{
    font-family: "ErasDemiITC";
}
u{
    font-weight: bold;
}
b, mark{
    font-family: "Calibri";
    font-size: "16px";
    color:"gray"
}
h6{
    color:"gray";
    padding-left: 0rem !important;
}
.exm-btn{
    margin: 4px 2px;
    text-decoration: none;
    background-color: #30a7e4;
    color: white;
    border:none;
    text-align: center;
    font-size: 16px;
    font-family: "Calibri";
    padding: 10px 25px;
    border-radius: 6px;
    cursor: pointer;
}

.maindiv {
    position: relative;
    margin-bottom: 14px;
    button {
        position: absolute;
        top: 60%;
        left: 9rem;
        background: #30a7e4;
        color: #ffff;
        padding: 2px 50px;
        border-radius: 25px;
    }

}

.trainingPng {
    position: relative;
    button {
          background: #30a7e4;
          color: #ffff;
          padding: 8px 28px;
          position: absolute;
          left: 4%;
          top: 85%;
          border-radius: 25px;
        //   width:300px;
    }
}
.classesRow{
    margin: 0px 16rem;
}
.anees{
   padding-left: 10rem;
}
label {
    line-height:15px
}
.listSpace {
    padding-left: 1rem;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
}
.posContent * {
    font-family: "Calibri";
  
}
.posContent p,  .posContent div{
    font-size: 16px;
}

.leftSpace {
    padding-left: 1rem;
} 
.contentSize {
    font-size: 20px;
}
.tablea, .tableb, .tablec{
    padding-left: 12rem;
    padding-right: 0rem;
}

#newtable1 
* {
    font-family: 'Calibri';
    font-size: 16px;
    width: 40%;
    border: 1px solid black;
}
#newtable1 tr, td, th{
    border: "1px solid black";
    width: "50%";
}
#newtable1 td {
    padding: 5px;
}
#newtable1 u{
    font-weight: normal;
    border: 0;
}
.contentHeading{
    color: #30a7e4;
    font-family: "ErasDemiiTC" !important;
    margin-bottom: 0;
}
.posContent h6, .posContent h5 .posContent h4 {
    margin: 0;
}
.headingList {
    display: flex;
    justify-content: space-between;
    margin: 0;
}
.headingList h6{
   margin:0;
}
.headingListAlign {
    padding-right: 25rem;
}
@media  (max-width:999px) {
    .trainingPng {
        margin: 0px !important;
    }
    .classesRow {
        margin: 0px !important ;
    } 
    .headingListAlign {
        padding-right: 0rem !important;
    }
        .MyCertificate {
            padding: 2rem !important;
        }
}


@media  (max-width:477px) {
    .anees{
        padding-left: 0px;
     }
    .baseimg{
        height: 224px;
    }
    .maindiv {
        position: relative;
        margin-bottom: 14px;
        button {
            position: absolute;
            top: 60%;
            left: 1rem;
            background: #30a7e4;
            color: #ffff;
            padding: 2px 50px;
            border-radius: 25px;
        }
    
    }
}
@media  (max-width:790px) {
    .trainingPng button {
        background: #30a7e4;
        color: #ffff;
        padding: 8px!important;
        position: absolute;
        left: 2%;
        top: 85%;
        border-radius: 25px;
        font-size: 10px;
        width: auto;
    }
    .baseimg{
        height: 224px;
    }
}
@media  (max-width:1380px) {
    .classesRow{
        margin: 0px 13rem;
    }
    // .row_col {
    //     margin: 0px 3rem 0px 16rem !important ;
    // }
    // .trainingPng {
    //     margin: 0px 3rem 0px 16rem !important ;
    // }
}