
  .btncus    {
    background: #fff!important;
    border-radius: 3px!important;
    border: 0px solid lightgrey!important;
  height: 55px!important;
  border-radius: 10px!important;
  padding-left: 15px!important;
  outline: none!important;
  font-family: Calibri,sans-serif!important;
  font-size: 18px!important;
}
#btncus{
  background: #fff!important;
  border-radius: 3px!important;
  /* border: 0.5px solid lightgrey!important; */
height: 55px!important;
border-radius: 10px!important;
padding-left: 15px!important;
outline: none!important;
font-family: Calibri,sans-serif!important;
font-size: 18px!important;
}
.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
right: 12px;
display: none!important;
}
.MuiAutocomplete-endAdornment {
right: 12px;
display: none!important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
font-size: 15!important;
font-weight: bold;
top:1px!important;
color: #00a0e3 !important;
/* color: rgba(0, 0, 0, 0.6); */
font-family: "Roboto","Helvetica","Arial",sans-serif;
font-weight: 400;
font-size: 1rem;
/* line-height: 1.4375em; */
letter-spacing: 0.00938em;
padding: 0;
position: relative;
display: block;
transform-origin: top left;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: calc(100% - 24px);
position: absolute;
left: 27px !important ;

pointer-events: none;
margin-top: -28px !important ;
background: #fff !important;
}
fieldset{
font-size: 20px!important;
font-weight: bold;
color: #00a0e3 !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
border: 0.5px solid lightgrey !important;
border-radius: 10px !important;

}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline 
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
font-size: 20px!important;
font-weight: bold;
color: #00a0e3 !important;
top:1px!important;

}
.MuiOutlinedInput-notchedOutline .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
font-size: 20px!important;
font-weight: bold;
color: #00a0e3 !important;
top:1px!important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
border: 0.5px solid #00a0e3 !important;
border-radius: 10px !important;

}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
font-size: 20px!important;
font-weight: 700!important;
color: #00a0e3 !important;
top:-1px!important;
z-index: 1!important;
background-color: #fff;
left: 40px !important ;
margin-top: 0px !important ;
margin-left: 0px !important ;
}
.MuiOutlinedInput-notchedOutline .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
font-size: 20px!important;
font-weight: bold;
color: #00a0e3 !important;
top:1px!important;

}


.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
position: absolute !important;
    font-size: 1rem !important ;
    top: 9px !important ;
    transform: translate3d(-10%, -90%, 0) !important ;
    opacity: 1 !important ;
    color: #757d85 !important;
    left: 1.5rem;
    color: #00a0e3 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
   padding: 0px !important;
   border: 0px solid lightgrey !important;
   
}

/* .Mui-focused {
 


} */

.MuiAutocomplete-root .Mui-focused {
  /* display: none !important ; */
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
  /* border-radius: 10px!important; */
  border: 0.5px solid #d3d3d3!important;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  border:1px solid #00a0e3!important ;
  border-radius: 12px !important ;
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 12px!important;
  border: 1px #d3d3d3 !important;
  
}