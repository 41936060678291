.preview {
    width: 200px;
    min-height: 190px;
    position: relative;
    background: #EEEEEE;
    border: 2px dashed hsl(0, 0%, 58%);
    box-sizing: border-box;
    justify-content: center;
    margin-left: 30px;
    display: flex;
    padding: 10px; 
    }
    .profile-form-groupp {
        position: relative;
        margin-bottom: 1.5rem;
        margin-right: 2rem;
        width: 270px;
        display: flex;
        flex-direction: column;
      }
      .accordion-header-1 button {
        background-color: #f4f6f9 !important;
        padding: 17px!important;
        font-size: 1.2rem!important;
        border-radius: 0!important;
        margin-right: 0;
        color: #525253;
        font-family: "ErasDemiITC";
    }
      .profile-form-controll {
        height: 55px;
        width: 270px;
        border-radius: 3px;
        border: 0.5px solid lightgrey;
        border-radius: 10px;
        padding-left: 15px;
        background: #fff;
        outline: none;
        font-family: Calibri,sans-serif;
        font-size: 18px;
      }
     
      .profile-form-controll:hover {
        border: 0.5px solid black;
      }
      
      .profile-form-control-placeholderr {
        position: absolute;
        left: 30px;
        /* bottom: 20px; */
        top: 15px;
        padding: 0px 5px 0px 5px;
        margin-left: 8px;
        transition: all 300ms;
        opacity: 0.5;
        pointer-events: none;
        /* z-index: 0; */
      }
      
      .profile-form-controll:valid+.profile-form-control-placeholderr {
        font-size: 95%;
        top: 3px;
        transform: translate3d(-10%, -90%, 0);
        opacity: 1;
        background-color: #fff;
        color: #757d85;
      }
      .profile-form-controll:focus+.profile-form-control-placeholderr {
        font-size: 95%;
        top: 3px!important;
        transform: translate3d(-10%, -90%, 0);
        opacity: 1;
        background-color: #fff;
        color: #0093dd!important;
      }
      .disabledfield {
        opacity: 0.5;
       }
      
      .profile-form-controll:focus {
        border: 1px solid #0093DD;
  
      }
    
    
    
    
    .inputStyle {
        height: 55px;
        width: 40px !important;
        border: 0px;
        background-color: #fff;
        font-size: 24px;
        border-bottom: 2px solid lightgrey;
        font-family: Calibri,sans-serif;
    }
    .containerStyle {
        width: 100%;
        margin: auto;
        display: block;
        justify-content: space-between;
    }
    .img{
        width: 70%;
        /* margin: auto; */
      display: block;
    }
    .terms{
    font-family: 'ErasMediumITC';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #8B8B8B;
    }
    .button {
        position: absolute;
        right: -40px;
        top: -30px;
    }
    .bg-light {
    background-color: #fff!important;
    border-radius: 10px !important;
    }
    .Register__Pos__Sub__Heading
    {
        font-family: 'ErasDemiITC';
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        color: #2b1870;
    }
    .agree {
        width: 40%;
        border-radius: 100px !important;
    }
    .pt-m {
    padding-top: 10px;
    height: 105px !important;
    }
    .edu {
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #525253;
    padding: 10px;
    padding-left: 1.5rem;
    
     }
    .agreeDiv {
        width: 80%;
        justify-content: center;
        padding-left: 12rem;
       
    }
    .agree {
        width: 50%;
        border-radius: 100px !important;
    }
    @media only screen and (max-width: 480px) {
    
    }
    @media (max-width:865px) {
        .signupimg {
            display: none;
        }
        .registration-card {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
      
    }
    .modal-content {
        margin: 100px 5% ;
        width:90%;
        background: hsl(0, 0%, 100%);
        border-radius: 17px;
        text-align: center;
       
        padding-bottom: 24px;
        position: relative;
        padding-top: 3rem;
           
        }
        /* .modal-btn-icon {
            font-size: 30px !important;
            color: #150E42 !important;
            position: absolute;
            top: 30px !important;
            right: 30px;
          
           
        } */
        .modal-block {
            display: block !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 3rem;
            padding-right: 2rem;
            margin-top: 2rem;
    
        }
      
    @media only screen and (max-width:1024px)
    and (min-width:768px){
        .privacypolicy-chechbox{
            width: 35.625rem;
        }
        p.p-topic-1, .p-topic-2, .p-topic-3{
            width:30rem ;
        }
        .topic-1, .topic-2, .topic-3{
            margin-left: -4rem;
        }
    }
    
    .from-colum {
        display: flex !important;
        flex-direction: column !important ;
    }
    
    .file-choose label {
        margin-left: 2px !important  ;
        font-family: 'ErasMediumITC';
        font-size: 18px;
    }
    .file_label {
            padding-top: 15px!important;
            padding: 15px;
            background: #0d6efd;
            color: #fff;
            padding-top: 8px;
            width: 38%;
            text-align: center;
            font-family: 'ErasMediumITC';
        
    }
    .radio-custom {
        opacity: 0;
        position: absolute;   
    }
    .radio-custom, .radio-custom-label {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        cursor: pointer;
    }
    radio-custom-label {
        position: relative;
    }
    .radio-custom + .radio-custom-label:before {
        content: '';
        background: #fff;
        border: 1px solid #0d6efd;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 0px;
        margin-right: 10px;
        text-align: center;
        line-height: 18px;
    }
    .radio-custom + .radio-custom-label:before {
        border-radius: 50%;
    }
    
    .radio-custom:checked + .radio-custom-label:before {
        content: "\f00c";
        font-family: 'FontAwesome';
        background-color: #0d6efd;
        color: #fff;
        outline: 0px;
    }
    .radio-custom:focus + .radio-custom-label {
        outline: 0px solid #0d6efd; /* focus style */
      }
      .Continue__Button {
        font-family: 'ErasMediumITC';
        padding-left: 4rem;
    }
    .accordion-button {
        position: relative!important;
        display: flex!important;
        align-items: center!important;
        width: 100%!important;
        font-size: 1rem!important;
        color: #212529!important;
        text-align: left!important;
        background-color: #fff!important;
        border: 0!important;
        border-radius: 0!important;
        overflow-anchor: none!important;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
    }
    
    file_label {
        background: #0d6efd!important;
        color: #fff!important;
        padding: 8px 7px 7px!important;
        /* width: 38%; */
        text-align: center!important;
        font-family: "ErasMediumITC"!important;
    }
    .line{
        margin-top: 50px;
        background: #CED2D9;
        width: 100%;
        height: 1px;
    }
    .main-div{
        background-color: #f4f6fb;
    }
    .Breadcrumb__Section , .breadcrumb{
        background-color: #f4f6fb;

    }
    .profileData-container{
        background-color: white;
        border-radius: 25px;
        padding: 25px;
        box-shadow: -1px 0px 22px -14px rgba(0,0,0,0.85);
        -webkit-box-shadow: -1px 0px 22px -14px rgba(0,0,0,0.85);
        -moz-box-shadow: -1px 0px 22px -14px rgba(0,0,0,0.85);
    }
    .text-primary{
        color:#00a0e3 !important;
    }
    .btn-primary{
        background-color: #0795de !important;
    }
    .btn-primary.file-btn{
        width: 140px !important;
        height: 40px !important;
        font-size: 16px !important;
        font-weight: normal !important;
    }
    .profile-form-control-placeholderr{
        color:#00a0e3 !important;
        font-size: 15px !important;
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
    }
    .subheads{
        font-family: 'ErasDemiITC';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color:"#525253";
      margin-left: 20px;
      padding: 30px;
      
      }
        
      .cardmain{
        display:flex ;padding-top:2rem;
      }

      .part{
         margin: 10px;
      }
      .Group1053{
        margin-top: -10px;
        width: 200px;
        height: 190px;
        margin-left: 30px;
      }
      .Group{
          width: 200px;
          align-items: center;
          justify-content: center;
          height: 200px;
          display: flex;
      }
       .positive-relative {
    position: relative;
}

.state {
    background-color: #fff;
    color: #00a0e3 !important;
    font-size: 15px !important;
    position: absolute;
    top: -3px;
    left: 42px;
}

.btn-buton button {
    border-radius: 8px !important ;
    background-color: #fff!important ;
}
.MuiButton-label { 
    font-family: 'Calibri' !important ;
    font-size: 18px;
}

.makeStyles-inputInput-4 {
    width: 100%;
    padding: 6px 0px 0px 0px !important ; 
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-left: calc(1em + 1px) !important ;
    font-size: 13px !important ;   
}
.iconselect{
    position: absolute;
    right: 6px;
    font-family: "ErasDemiITC";
    font-size: 0.8rem;
}
.mrr {
    margin-left: 1rem !important;  
}
.wd{
   width: 320px!important ;  
}
.accordion-header-1 {
    background-color: #f4f6f9 !important;
}

 @media only screen and (max-width : 468px )  {
    .profile-form-control-placeholderr{
        font-size: 13px !important;
    }
    .text-secondary{
        line-height: 20px;
    }
    .cardmain {
        display: block;
        padding-top: 2rem;
    }
    .part{
        padding-top: 1rem;
    }
  
 }
 @media only screen and (max-width : 769px )  {
     .agreeDiv {
         width: 100%;
        justify-content: center;
        padding-left: 0rem!important;
    }
   
    .Group1053 {
        margin-top: -10px;
        width: 162px;
        height: 154px;
        margin-left: 0px;
    }
 }
  @media only screen and (min-width : 987px ) and (max-width : 1200px )  {
    .css-viou3o-MuiAutocomplete-root{
        width: 100%!important;
    }
    .wd {
        width: 280px!important;
    }
 }
  @media only screen and (max-width : 991px )  {
    .css-viou3o-MuiAutocomplete-root{
        width: 100%!important;
    }
    .wd {
        width: 100%!important;
    }

     .mrrs{
        margin-left: 1rem !important
    }
 }