/**************************/

/*     General Styles     */

/**************************/

body,
html {
    width: 100vw;
    background-color: #edf5fb;
}

@font-face {
    font-family: ErasDemiITC;
    src: url(/src/assets/fonts/eras-demi-itc/ErasDemiITC.ttf);
}

@font-face {
    font-family: ErasMediumITC;
    src: url(/src/assets/fonts/eras-medium-itc/ErasMediumITC.ttf);
}

@font-face {
    font-family: ErasLightITC;
    src: url(/src/assets/fonts/eras-light-itc/ErasLightITC.ttf);
}

@font-face {
    font-family: ErasBoldITC;
    src: url(/src/assets/fonts/eras-bold-itc/ErasBoldITC.ttf);
}

@font-face {
    font-family: Calibri;
    src: url(/src/assets/fonts/calibri/Calibri.ttf);
}

body,
p {
    /* color: #6b747b;  */
    color: #525253;
    /* font: 400 1rem/1.625rem 'ErasDemiITC', sans-serif; */
    font: 400 1rem/1.625rem "ErasDemiITC", sans-serif;
}

h1 {
    color: #29166f;
    /* font-weight: 700; */
    font-size: 2.5rem;
    line-height: 3.125rem;
    letter-spacing: 0.3px;
}

h2 {
    color: #525253;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.625rem;
    letter-spacing: -0.4px;
}

h3 {
    color: #252c38;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    letter-spacing: -0.3px;
}

h4 {
    color: #525253;
    font-weight: 500;
    font-size: 1.27rem;
    line-height: 1.4rem;
    letter-spacing: -0.5px;
    margin-bottom: 2.75rem;
}

h5 {
    color: #525253;
    font-weight: 600;
    font-size: 1.37rem;
    line-height: 2rem;
    letter-spacing: -0.5px;
    margin-bottom: 2.75rem;
    /* font-family: 'ErasBoldITC'; */
}

h6 {
    color: #525253;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: -0.5px;
    margin-bottom: 0.8rem;
}
.p-error {
    font-size: 0.7rem;
    color: red;
    font-family: "Calibri";
}
p.p-faqs-para {
    font-family: "calibri";
    color: #525253;
    font-size: 1.1rem;
}
span {
    color: #535252;
    font-family: "ErasDemiITC";
    font-size: 1rem;
}
.span-text,
.span-desc {
    color: #535252;
    font-family: "Calibri";
    font-size: 1rem;
}

.h1-large {
    font-size: 2.875rem;
    line-height: 3.5rem;
    font-family: "ErasMediumITC";
}
.insurance-card-text {
    display: "flex";
    flex-direction: "column";
    justify-content: "flex-start";
    margin: "2px";
    align-items: "start";
}
.outbtnp {
    font-family: "ErasMediumITC";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    
    color: #3A3285;
    
}
.barline{
    position: absolute;
    width: 1px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    left: -3px;
    font-size: 50px;
    top: 7px;
}

.outbtn {
    border: 1px solid #184AD8;
    color: #3A3285;
    padding: 8px 0px;
    /* margin-top: 2px; */
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    margin-top: 7px;
}
.h1-heading {
    font-size: 3rem;
    font-family: "ErasDemiITC";
    color: #3a3285;
}

.sub-heading {
    font-family: "ErasMediumITC";
    color: #0093dd;
    margin-top: 1rem;
    font-size: 1.5rem;
}

.p-large {
    font-size: 1rem;
    line-height: 1.75rem;
    letter-spacing: 0.1rem;
    color: #29166f;
}

.p-small {
    font-size: 0.775rem;
    line-height: 1.5rem;
    color: #ffffff;
    margin-left: 36rem;
}

.paragraph {
    font-size: 0.75rem;
    line-height: 1.1rem;
    margin-bottom: 2rem;
}

.li-space-lg li {
    margin-bottom: 0.5rem;
    color: #525253;
    font-size: 1.1rem;
    font-family: "Calibri";
}

img.tile {
    margin-right: 1rem;
    margin-top: 0.3rem;
    width: 3%;
}

a {
    color: #6b747b;
    text-decoration: underline;
}

a:hover {
    color: #6b747b;
    text-decoration: underline;
}

.no-line {
    text-decoration: none;
}

.no-line:hover {
    text-decoration: none;
}

.bg-gray {
    background-color: #f1f9fc;
}

.btn {
    font-family: "Calibri";
    padding: 0.64rem 1.2rem !important;
    font-size: 0.8rem !important;
    border-radius: 0.5rem !important;
    margin-right: -1rem;
}

.btn_verify_continue{
    font-family: "Calibri";
    padding: 0.64rem 1.2rem !important;
    font-size: 0.8rem !important;
    border-radius: 1.5rem !important;
    margin-right: -1rem; 
}

.btn-outline-info {
    color: #29166f;
    border-color: #184ad8;
}

/* .btn-outline-info:hover {
    color: #fff;
    background-color: #29166f;
    border-color: #29166f;
} */
iframe {
    pointer-events: none;
}
.pos.cattle-insurance .accordion-button {
    color: #0c63e4 !important;
    background-color: #e7f1ff !important;
    margin-bottom: 1rem !important;
    font-size: 1rem !important;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
        
}
.pos.cattle-insurance .title {
    font-weight: 700 !important;
}
.pos.cattle-insurance .accordion-header {
    font-family: "ErasDemiITC";
}
.pos.cattle-insurance .radio-label-text {
    font-size: 18px !important;
    color: #29166F !important;
    font-weight: 400 !important;
    font-weight: bold !important;
    font-family: "calibri";
}

.pos.cattle-insurance .accordion-body {
    padding: 1rem 1.25rem;
}
.pos.cattle-insurance .form .col span {
    font-size: 15px;
    color: #656565;
    font-family: "calibri";
    font-weight: bold;
    background: #fff;
}
.pos.cattle-insurance .cattleForm .col input, .pos.cattle-insurance .me-2 {
    height: 14px;
    width: auto;
}
.pos.cattle-insurance .cattleForm {
    margin-bottom: 20px !important;

}
.pos.cattle-insurance .form .col label {
    color: #00a0e3 !important;
    font-size: 15px !important;
    top: -1rem;
    margin-left: 10px;
    max-width: 90%;
    left: 0;
    background-color: white;
    padding: 0.4rem;
    font-family: "calibri";
    font-weight: bold;
    padding-left: 5px !important;
}
.pos.cattle-insurance .form .col .searchable-select, .pos.cattle-insurance .form .col input[type="radio"] {
    height: 14px;
    border-radius: 3px;
    font-family: "calibri";
    width: auto;
}

.pos.cattle-insurance .form .col .searchable-select,
.pos.cattle-insurance .form .col input {
    height: 3.3rem;
    border-radius: 3px;
    font-family: "calibri";
}
 .pos.cattle-insurance .css-1fdsijx-ValueContainer {
height: 3.2rem;
 }
 .pos.cattle-insurance  .css-16xfy0z-control {
    background: none;
    
    font-family: 'Calibri';
 }
.pos.cattle-insurance .btn-primary:hover {
    color: #fff !important;
    background-color: #0b5ed7 !important;
    border-color: #0a58ca !important;
}


.pos.cattle-insurance .img-preview {
    width: 200px;
    min-height: 190px;
    padding: 10px;
    position: relative;
    background: #eee;
    border: 2px dashed #949494;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
}
.pos.cattle-insurance .img-preview img {
    height: -webkit-fill-available;
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    display: block;
}
.pos.cattle-insurance .img-preview .clear {
    position: absolute;
    right: -32px;
    top: -30px;
    height: 60px;
    width: 60px;
    cursor: pointer;
}
.pos.cattle-insurance .form button {
    width: 150px !important;
    border-radius: 15px !important;
    font-size: 12px !important;
    font-weight: normal !important;
}


.pos.cattle-insurance .form .col select,
.pos.cattle-insurance .form .col .searchable-select .css-319lph-ValueContainer {
    height: 55px;
}
 .pos.cattle-insurance input[type="checkbox"], .pos.cattle-insurance input[type="radio"] {
    margin-bottom: 0px !important;
 }
.pos.cattle-insurance .form .col input,
.pos.cattle-insurance .form .col textarea,
.pos.cattle-insurance .form .col select {
    border-radius: 3px;
    border: 0.5px solid #d3d3d3;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;
    outline: none;
    font-family: Calibri, sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
}
.pos.cattle-insurance .searchable-select {
    margin-bottom: 10px !important;
}
.pos.cattle-insurance .form .col input[type="radio"] {
    transform: scale(1.5);
    cursor: pointer;
}
.pos.cattle-insurance .number {
    font-weight: 400;
    font-size: 15px;
    color: #525253;
    padding-bottom: 10px;
}
.pos.cattle-insurance .react-datepicker__navigation-icon--next::before {
    left: 25px !important;
}

.pos.cattle-insurance .react-datepicker__navigation-icon--previous::before {
    right: 25px;
}
.pos_filter {
    display: flex;
    flex-direction: column;
    height: 3.5rem;
    background-color: #ebeff2;
}
.pos_filter label {
    font-size: 12px;
    color: #000;
    font-weight: 700;
}
.pos_filter input,
.pos_filter select {
    outline: none;
    border: none;
    background-color: transparent;
    display: block;
    line-height: 1.2em;
    font-size: 10pt;
}
.primary_btn {
    background-color: #2196f3;
    color: white;
    border: none;
 
}
.pos_filter_btn {
    border-radius: 25px !important;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    width: 10rem;
    color: black !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    border: 1px solid black;
}
.pos_filter_btn.primary_btn:hover {
    color: #fff !important;

}
::placeholder {
    color: gray;
    opacity: 0.6;
    font-weight: 100 !important;
}
.table_paginate {
    width: 100%;
    display: flex;
    justify-content: end;
}
.table_paginate .pagination {
    display: flex;
    padding: 10px;
    margin: 0;
    float: none;
    justify-content: flex-end;
    position: unset;
}
.table_paginate .page-item .page-link {
    border-radius: 0px !important;
    padding: 0.375rem 0.75rem !important;
    color: #0d6efd;
}
.table-responsive .badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    width: auto;
}
.table-responsive .bg-success {
    background-color: #198754 !important;
}
@media (min-width: 1400px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1350px;
    }
}

/**********************/

/*     Navigation     */

/**********************/

.navbar {
    background-color: #f1f9fc;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0rem 1rem;
    /* box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%); */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* border-radius: 40px; */
}

.navbar .navbar-brand {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.navbar .logo-image img {
    width: 217px;
    height: 35px;
   /* // margin-left: 2.2rem; */
}

.navbar .logo-text {
    color: #252c38;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1rem;
    text-decoration: none;
}

.offcanvas-collapse {
    position: fixed;
    top: 3.25rem;
    /* adjusts the height between the top of the page and the offcanvas menu */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f1f9fc;
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.navbar .navbar-nav {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
}

.navbar .nav-item .nav-link {
    padding-top: 1.2rem;
    /* padding-bottom: 0.625rem; */
    color: #29166f;
    text-decoration: none;
    transition: all 0.2s ease;
    /* font-size: 18px; */
    font-weight: 700;
    /* margin-right: 0.8rem; */
    font-family: "ErasMediumITC";
}

.navbar .nav-items .nav-links {
    padding-top: 1.2rem;
    color: #3a3285;
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 15px;
    font-weight: 500;
    font-family: "ErasMediumITC";
    margin-right: 0.8rem;
    margin-left: 15px;
}

.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
    color: blue;
}

/* Dropdown Menu */

.navbar .dropdown .dropdown-menu {
    animation: fadeDropdown 0.2s;
    /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.navbar .dropdown-menu {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: none;
    background-color: #f1f9fc;
}

.navbar .dropdown-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6b747b;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    text-decoration: none;
}

.navbar .dropdown-item:hover {
    background-color: #f1f9fc;
    color: blue;
}

.navbar .dropdown-divider {
    width: 100%;
    height: 1px;
    margin: 0.5rem auto 0.5rem auto;
    border: none;
    background-color: #d4dce2;
}

/* end of dropdown menu */

.navbar .app-store-icons {
    display: block;
}

.navbar .app-store-icons a {
    text-decoration: none;
}

.navbar .navbar-toggler {
    padding: 0;
    border: none;
    font-size: 1.25rem;
}

img.img-chevron-down {
    margin-left: 3px;
}

img.img-file-checked {
    width: 18%;
    /* padding-right: 6px; */
    margin-right: 5px;
}
img.img-dial {
    width: 13.5%;
    margin-right: 13px;
}

/*custom file style*/
.file_label{
    padding: 10px;
    background: #0d6efd;
    color: #fff;
    padding-top: 10px;
    width: 45%;
    text-align: center;
}

.file_ {
    display: none;
}
  /*custom file style*/



img.img-mail {
    margin-right: 5px;
}
img.img-customer-care {
    width: 13.5%;
    /* padding-right: 6px; */
    margin-right: 13px;
}
img.img-customer-cares {
    margin-right: 13px;
    color: #29166f;
    width: 15px;
}

.edit_icon {
    position: absolute;
    left: -47rem;
    top: -11rem;
}

/*****************/

/*    Header     */

/*****************/

.header {
    padding-top: 7rem;
    padding-bottom: 7rem;
    text-align: center;
    background: url(/src/components/HomePage/Group\ 154.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    position: relative;
}

.accordion-header-1 {
    background-color: #fff !important;
}

.accordion-header-1 button {
    background-color: #eff7fd !important;
    font-size: 1.2rem!important;
    border-radius: 0!important;
    margin-right: 0;
    color: #525253;
    font-family: "ErasDemiITC";
}
.accordion-header-2 {
    background-color: white !important;
}

.accordion-header-2 button {
    background-color: white !important;
    font-size: 1.2rem!important;
    border-radius: 0!important;
    padding: 18px!important;
    margin-right: 0;
    color: #525253;
    font-family: "ErasDemiITC";
}

.container.header-img {
    margin-bottom: 2rem;
}

.header .text-container {
    margin-bottom: 3.2rem;
    margin-left: 1.75rem;
}

.header .h1-large {
    margin-bottom: -0.25rem;
    color: #3a3285;
}

.header .h1-too-large {
    font-weight: 500;
    font-size: 3.72rem;
    color: #0093dd;
    font-family: ErasMediumITC;
}

.header .p-large {
    margin-top: 2.5rem;
    font-weight: 900;
    /* font-family: 'ErasMediumITC'; */
    font-family: "ErasLightITC";
    letter-spacing: 2.3px;
}

h2.h2-header {
    /* font-family: 'ErasBoldITC'; */
    font-size: 1.35rem;
}

.paragraph-header {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    font-family: "ErasMediumITC";
    color: #525253;
}

.faqText{
    font-family: "ErasDemiITC";
    color: #525253;
}

.header .p-large .underline {
    letter-spacing: 0rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration-thickness: 1px;
    font-family: "ErasDemiITC";
}

.header .btn-solid-lg {
    margin-right: 0.25rem;
    margin-bottom: 1.25rem;
    margin-left: 0.25rem;
}

img.img-fluid.img-placing {
    max-width: 100%;
    height: 24.5rem;
    margin-right: 3.7rem;
    margin-top: -5.45rem;
}

img.img-fluid.img-place {
    max-width: 100%;
    height: 28.5rem;
    margin-right: 3.7rem;
    margin-top: -0.45rem;
}

img.img-fluid.img-basic4 {
    height: 38rem;
}

.card .card-no-1 {
    margin-left: 3rem;
}

/* Card design */

.cards-banner {
    margin: -2rem 5.5rem 0rem 0rem;
    cursor: pointer;
    position: absolute;
}

.order-card {
    color: #fff;
}

.bg-c-white {
    background: #ffffff;
}

p.m-b-0 {
    font-size: 0.8rem;
    color: #29166f;
    font-family: "ErasMediumITC";
}

.card-features {
    border-radius: 15px;
    background: transparent;
    -webkit-box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card-features:hover {
    border: 1px solid rgba(0, 147, 221, 0.59);
    transition: all 0.1s ease-in-out;
}

.card .card-block:hover {
    padding: 24px;
}

.card .card-block {
    padding: 25px;
}

.card .card-block1:hover {
    padding: 24px;
}

.card .card-block1 {
    padding: 25px;
    height: 140px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
    width: 43%;
}

.f-right {
    float: right;
    color: #0093dd;
}

p {
    margin-top: 0;
    margin-bottom: 0rem;
    font-family: 'Calibri';
}

h4.text-left.car-insurance {
    margin-bottom: 1.5rem;
}

h4.text-left.bike-insurance {
    margin-bottom: 1.5rem;
}

h4.text-left.health-insurance {
    margin-bottom: 1.5rem;
}

h4.text-left.life-insurance {
    margin-bottom: 1.5rem;
}

img.f-left.img-width-life-insurance {
    width: 34%;
    margin-right: 2.6rem;
}

img.f-left.img-width-Health-insurance {
    width: 34%;
    margin-right: 2.6rem;
}

img.f-left.img-width-car-insurance {
    width: 34%;
    margin-right: 2.6rem;
}

img.f-left.img-width-bike-insurance {
    width: 34%;
    margin-right: 2.6rem;
}

/*********************/

/*     Basic 3     */

/*********************/

.basic-3 {
    /* padding-top: 5.5rem;
    padding-bottom: 5.75rem; */
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.basic-3 .image-container {
    margin-bottom: 3rem;
}

.basic-3 .image-container .img-details {
    max-width: 100%;
    height: 24.5rem;
}

.basic-3 h2 {
    margin-bottom: 1rem;
}

.basic-3 .list-unstyled {
    margin-bottom: 1.75rem;
}

.basic-3 .list-unstyled .fas {
    color: #594cda;
    font-size: 0.75rem;
    line-height: 1.625rem;
}

.basic-3 .list-unstyled .media-body {
    margin-left: 0.375rem;
}

.basic-3 .btn-solid-reg {
    margin-right: 0.375rem;
}

/*********************/

/*     Basic 4     */

/*********************/

.basic-4 {
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.basic-4 h2 {
    margin-bottom: 1rem;
    letter-spacing: 0px;
}

.basic-4 .text-container {
    margin-bottom: 4rem;
}

.basic-4 .p-title {
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    color: #525253;
}

/*********************/

/*  partners    */

/*********************/

.partners {
    /* background: rgba(0, 147, 221, 0.12); */
    padding-bottom: 4rem;
}

.partner-set-1 {
    margin: 0rem 0rem 2rem 0rem;
}

.card-partners {
    background: #f6f6f6;
    border-radius: 15px;
    border: 1px solid #8cbcd8;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

img.img-fluid.img-partners {
    max-width: 97%;
    /* text-align: center; */
    /* margin-left: 0.3rem; */
    padding: 1rem 1rem;
}

.col-xl-3.col-sm-6.col-12.partners-content {
    padding-left: 2rem;
}

/*********************/

/*        FAQS       */

/*********************/

.faqs {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.card-faqs {
    border: none;
    border-radius: 20px;
}

.btn-faqs {
    /* padding: 0.75rem !important; */
    font-size: 1.2rem !important;
    border-radius: 0rem !important;
    margin-right: 0rem;
    color: #525253;
}

img.tile-faqs {
    margin-right: 1rem;
    margin-top: 0.3rem;
    width: 3%;
}

div#accordionExample {
    width: 100%;
}

.card-faqs {
    border-radius: 10px;
}

.card-bg-1 {
    background: #eff7fd;
}

.card-bg-2 {
    background: #fcfdff;
}

img.img-chevron-down-faqs {
    margin-top: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
}

/*********************/

/*  RELATED-ARTICLES */

/*********************/

.related-articles {
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.related-articles-size {
    margin-left: 1rem;
    margin-bottom: 3rem;
}

.p-related-articles {
    font-family: Calibri;
    /* font-style: normal;
    font-weight: normal; */
    font-size: 17px;
    line-height: 30px;
}

.card-body-related-articles {
    margin-top: 1.5rem;
    padding: 0rem;
}

.content {
    font-family: "Calibri";
}

img.card-img-top.img-faqs {
    border-radius: 10px;
}

.related-articles-find-more {
    padding-left: 39rem;
}

/******************/

/*     Footer     */

/******************/

.footer {
    padding-top: 6rem;
    padding-bottom: 3rem;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(197, 234, 249, 1));
    text-align: center;
}

.footer a {
    text-decoration: none;
}

.footer h4 {
    margin-bottom: 1.875rem;
}

.footer .fa-stack {
    width: 2em;
    margin-bottom: 1.25rem;
    margin-right: 0.375rem;
    font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
    color: #252c38;
    transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
    color: #ffffff;
    transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
    color: #ffffff;
}

.footer .fa-stack:hover .fa-stack-2x {
    color: #252c38;
}

p.p-small-footer {
    font-size: 0.938rem;
    line-height: 1.375rem;
    margin-bottom: 1rem;
    font-family: "Calibri";
}

.footer-content {
    background: #dff1f7;
}

a.a-text {
    font-size: 0.75rem;
    text-decoration: none;
    color: #525253;
    font-family: "ErasMediumITC";
}

ul.list-unstyled.text-content-2 {
    padding-left: 9rem;
    padding-top: 2.8rem;
}

/*********************/

/*     Copyright     */

/*********************/

.copyright {
    padding: 1.5rem;
    background-color: #8cbcd8;
    text-align: center;
}

.copyright a {
    text-decoration: none;
    color: #ffffff;
}

.p-small-copyright {
    font-size: 0.775rem;
    line-height: 1.5rem;
    color: #ffffff;
    margin-left: 37rem;
    font-family: "ErasMediumITC";
}

/*************************/

/*     Media Queries     */

/*************************/

@media only screen and (max-width: 480px) {
    .h1-large {
        font-size: 1.875rem;
    }
    .header .h1-too-large {
        font-size: 2.72rem;
    }
    /* Header */
    .header .text-container {
        margin-left: 0rem;
    }
    img.img-fluid.img-placing {
        max-width: 100%;
        height: 17.5rem;
        margin-right: 0rem;
        margin-top: -1.45rem;
    }
    .cards-banner {
        margin: 2rem 0rem 0rem 0rem;
        position: unset;
    }
    .card-features {
        margin-bottom: 1.5rem;
    }
    .outbtn {
        width:50%;
    }
    p.m-b-0 {
        margin-top: 4rem;
        font-size: 0.8rem;
    }
    /* Navigation bar */
    img.img-chevron-down {
        width: 7%;
    }
    .mobile{
        margin-top: 4rem;
    }
    img.img-file-checked {
        width: 6%;
        margin-right: 5px;
    }
    img.img-customer-care {
        width: 5.5%;
        margin-right: 6px;
    }
    /* Basic-4 */
    img.img-fluid.img-basic4 {
        height: 27rem;
    }
    .basic-4 .image-container {
        margin-left: 0.5rem;
        margin-top: -2.5rem;
    }
    /* Partners */
    .h2-heading-partners {
        margin-left: 7.5rem;
        margin-top: 2rem;
    }
    .p-heading-partners {
        margin-left: 1.5rem;
        margin-bottom: 2rem;
        font-size: 1rem;
    }
    .col-xl-3.col-sm-6.col-12.partners-content {
        padding-left: 1rem;
        margin-bottom: 1.5rem;
    }
    /* Insurance FAQS*/
    .h2-heading-faqs {
        font-size: 1.6rem;
    }
    .p-heading-faqs {
        margin-left: 1rem;
        margin-bottom: 3.5rem;
        font-size: 1rem;
    }
    div#accordionExample {
        margin-left: 1.5rem;
    }
    .btn-faqs {
        font-size: 1rem !important;
    }
    /* Related Articles */
    .related-articles-find-more {
        padding-left: 9rem;
    }
    .h2-heading-related-articles {
        /* margin-left: 6rem; */
        margin-bottom: 2rem;
        font-family: "ErasBoldITC";
        font-size: 1.6rem;
    }
    .related-articles-size {
        margin-left: -1rem;
        margin-bottom: 1rem;
    }
    /* Copyrights */
    .p-small-copyright {
        margin-left: 0rem;
    }
    ul.list-unstyled.text-content-2 {
        padding-left: 0rem;
        padding-top: 1rem;
    }
}

/* Min-width 768px */

@media (min-width: 768px) {
    /* Header */
    .header {
        padding-top: 9rem;
    }
    /* end of header */
    /* Extra Pages */
    .ex-basic-1 .text-box {
        padding: 1.75rem 2rem 0.875rem 2rem;
    }
    /* end of extra pages */
}

/* end of min-width 768px */

/* Min-width 992px */

@media (min-width: 992px) {
    /* General Styles */
    .h2-heading {
        width: 9.25rem;
        margin-right: auto;
        margin-left: auto;
    }
    .h2-heading-faqs {
        /* width: 11.25rem; */
        text-align: center;
        font-size: 1.37rem;
        /* font-family: 'ErasBoldITC'; */
    }
    .h2-heading-related-articles {
        width: 16.25rem;
        margin-right: auto;
        margin-left: 31rem;
        margin-bottom: 4rem;
        /* font-family: 'ErasBoldITC'; */
        font-size: 1.37rem;
    }
    .h2-heading-partners {
        width: 11.25rem;
        margin-right: auto;
        margin-left: 37.5rem;
        font-size: 1.35rem;
        margin-top: 3rem;
        /* font-family: 'ErasBoldITC'; */
    }
    .p-heading {
        width: 46rem;
        margin-right: auto;
        margin-left: auto;
    }
    .p-heading-faqs {
        text-align: center;
        margin-bottom: 3.5rem;
        font-size: 1.25rem;
    }
    .p-heading-partners {
        color: #525253;
        /* width: 60rem;  */
        margin-right: auto;
        margin-left: 28.5rem;
        margin-bottom: 3rem;
        font-size: 1.25rem;
    }
    /* end of general styles */
    /* Navigation */
    .navbar {
        padding-top: 0px;
        background-color: transparent;
        border-bottom: none;
        transition: all 0.2s;
    }
    .navbar.top-nav-collapse {
        /* padding-top: 22px;
        padding-bottom: 1rem; */
        background-color: #ffffff;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%);
    }
    .offcanvas-collapse {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        width: auto;
        padding-right: 0;
        padding-left: 0;
        background-color: transparent;
        overflow-y: visible;
        visibility: visible;
    }
    .offcanvas-collapse.open {
        -webkit-transform: none;
        transform: none;
    }
    .navbar .navbar-nav {
        margin-top: 0;
        margin-bottom: 0;
    }
    .navbar .nav-item .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.625rem;
    }
    .navbar .dropdown-menu {
        margin-top: 0.25rem;
        box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.05);
    }
    .navbar .dropdown-divider {
        width: 90%;
    }
    .navbar .app-store-icons {
        margin-left: 0.875rem;
    }
    /* end of navigation */
    /* Header */
    .header {
        text-align: left;
    }
    .header .text-container {
        margin-top: 8rem;
    }
    .header .btn-solid-lg {
        margin-right: 0.5rem;
        margin-left: 0;
    }
    /* end of header */
    /* Details 2 */
    .basic-3 .image-container {
        margin-bottom: 0;
    }
    /* end of details 2 */
    /* Details 3 */
    .basic-4 .text-container {
        margin-bottom: 0;
    }
    /* end of details 3 */
    /* Copyright */
    .copyright {
        text-align: left;
    }
    .copyright .list-unstyled li {
        display: inline-block;
        margin-right: 1rem;
    }
    .copyright .statement {
        text-align: right;
    }
    /* end of copyright */
}

/* end of min-width 992px */

/* Min-width 1200px */

@media (min-width: 1200px) {
    /* General Styles */
    .h1-large {
        font-size: 3.7rem;
        line-height: 4.85rem;
        font-weight: 300;
    }
    /* end of general styles */
    /* Header */
    .header {
        padding-top: 11rem;
        padding-bottom: 8rem;
    }
    .header .text-container {
        margin-top: 1rem;
        margin-right: 3rem;
    }
    .header .image-container {
        text-align: right;
    }
    /* end of header */
    /* Details 2 */
    .basic-3 .image-container {
        margin-right: 3.5rem;
        margin-left: 12rem;
    }
    .basic-3 .text-container {
        margin-top: 3.25rem;
        margin-right: 7.5rem;
    }
    /* end of details 2 */
    /* Details 3 */
    .basic-4 .text-container {
        margin-top: 4.25rem;
        margin-left: 12.5rem;
    }
    .basic-4 .image-container {
        margin-left: -0.5rem;
        margin-top: -6.5rem;
    }
    /* end of details 3 */
}

/* end of min-width 1200px */

.bootstrap-button {
    width: 11.813rem;
    height: 2.928rem;
    /* left: 373px;
top: 2117px; */
    color: #ffffff;
    background: #0093dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    border: none;
}

.btn-link {
    text-decoration: none;
}

.h2-header-text {
    font-family: "ErasDemiITC";
    font-size: 1.3rem;
    margin-top: 1.5rem;
}

.footer-image {
    width: 100%;
    margin-top: -5.5rem;
}

.address {
    display: flex;
    align-items: baseline;
    margin-left: 9rem;
}

.paragraph {
    color: #0093dd;
    font-family: "ErasMediumITC";
    font-weight: bold;
    font-size: 15px;
}

.subParagraph {
    font-family: "ERASMEDIUMITC";
    margin-left: 5px;
    color: #0093dd;
    
}
p.p-faqs-para {
    font-family: "calibri";
    color: #525253;
    font-size: 1.1rem;
    padding: 10px!important;
}
@media only screen and  (max-width: 1024px) {
    img.img-fluid.img-placing {
        max-width: 100%;
        height: 24.5rem;
       
        margin-top: 0rem;
    }
    /* .card .card-block1.style.span {
       margin-left: -1rem;
    } */
   
}

@media (max-width:480px) {
    .react-tabs {
        display: flex;
        margin-left: -30px;
        color: black;
        background: white;
    }
    .react-tabs__tab-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding-top: 5rem;
        color: white;
        background: white;
        border-right: 1px solid #e7e6f1;
        width: 44%;
    }
    .react-tabs__tab-panel--selected {
        display: block;
        /* z-index: 10000; */
        background-color: #fff;
    }
}
@media only  screen and (max-width:1024px)
and (min-width:768px){
    .col-md-4 {
        flex: 0 0 auto;
        width: 50%;
    }
    img.img-fluid.img-placing {
        max-width: 100%;
        height: 24.5rem;       
        margin-top: 0rem;
        margin-left: 6rem;
    }
}

.fs-7 {
    font-size: 15px !important;
}

.css-13cymwt-control{
    border-radius: 10px;
}   
.pos.cattle-insurance .form .col input{
 color: #525253;
 font-weight: 400;
}


/*testing */
.footer-links .list-unstyled .li-item {
    display: flex;
    align-items: center;
}
.Register__Pos__para {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Register__Pos__para span {
    color: #949494;
    font-family: 'Calibri';
    font-size: 16px;
    width: 25%;
}
 .setWidth {
     width: 75% !important;
 }
 .backArrow {
    display: none;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    padding-right: 5px;
 }
 .icueNavBarLst .accordion-item{
    border-bottom: 1px solid lightgrey !important;
 }
 .icueNavBarLst .accordion {
     border-bottom: none;
 }
 @media (min-width: 992px) {
     .row-cols-lg-3>* {
         width: 32%;
     }

     .row-cols-lg-4>* {
         width: 24%;
     }

     .row-cols-lg-2>* {
         width: 49%;
     }
 }
 @media (max-width: 1200px) {
    .navBarResponsive {
        width: 80% !important;
    }
    .privacypolicy-chechbox {
        width: 100% !important;
    }
    .setWidth {
      width: 50% !important;
    }
 }
 @media (max-width: 999px) {
    .mainBodyContainer {
        margin-top: 11rem !important;
    }
    .custom-form-select {
        width: 25% !important;
    }
    .classesRow.rowWidth {
        width: 100% !important;
    }
    .table-responsive th {
        font-size: 10px !important;
    }
    .table-responsive tD {
        font-size: 8.5px !important;
    }
    .ProductsAlign {
        float: none !important;
    }
    .pedVac {
        margin-top:30px;
    }
    .insType {
        margin: 7.5px 0;
        width: 100%;
    }
    .setWidth {
        width: 100% !important;
    }
    .aggrementUploadStep {
        margin-left: auto !important;
    }
    #plremove {
        padding-left: 0 !important;
    }
    .tbody-section__main-content {
        padding: 20px 0px !important;
    }
    .training h1 {
        font-size: 24px !important;
    }
    .backArrow {
     display: block;
    }
    .Page-content p {
     text-align: left !important;
    }
    #dtBasicExample .sorting-num3 {
        padding-left: 10px !important;
    }
 }
  @media (max-width: 992px) {
    .navBarResponsive {
        width: 30% !important;
    }
    .barline {
        display: none;
    }
    .navBarResponsive {
        background-color: white;
    }
    .navBarResponsive .navBarResponsiveSection {
        display: block;
    }
   .navBarResponsive .navWidth {
        width: 100% !important;
        line-height: normal;
    }
    .navBarResponsive .navWidth .outbtn {
        width: 50% !important;
       margin-right: auto !important;
    }
    .navBarResponsive .navWidth .dropdown-menu {

        width: 100% !important;
        top: 22px !important;
        left: 4px !important;

    
    }
   .navWidth .nav-item {
    text-align: left;
    padding: 7px 20px;
    margin: 0 !important;

    }
  }
   @media (max-width: 780px) {
    .navBarResponsive {
        width: 40% !important;
    }
    .backArrow {
        font-size: 16px;
        padding-top: 5px;
    }
   }
 @media (max-width: 600px) {
     .parent_shit {
        margin-top: 45px !important;
     }
    .table-responsive th {
        font-size: 7px !important;
    }

    .table-responsive tD {
        font-size: 6.5px !important;
    }
    .pcLogo {
    width: 10rem !important;
    }
    .statusText {
        font-size: 12px;
    }
    .userPic{
        width: 25px !important;
    }
    .pagination .page-link {
        font-size: 12px !important;
    }
    .mainHeading {
        font-size: 14px;
    }
    .addbtn, .addbtn i{
        font-size: 10px !important;
    }
   .btn.pos_filter_btn {
       font-size: 10px !important;
       padding: 0.4rem !important;
   }
   .paglabel span {
       font-size: 11px !important;
   }
   .icueNavBarLink {
      font-size: 12px;
    }
    .accordion .icueNavBarItm {
        border-bottom: 0;
        font-size: 12px !important;
    }
    .navBarResponsive {
        width: 50% !important;
    }
    .navHeight {
        height: 90px !important;
    }
    .Register__Pos__para span {
        width: auto
    }
 }
 
  @media (max-width: 480px) {
.Register__Pos__para span {
        width: 50%
    }
      .pcLogo {
          width: 8rem !important;
      }
      .statusText {
          font-size: 10px;
      }
         
  }
  
  

@media (max-width: 450px) {
    #responsive-navbar-nav {
        width: 100% !important;
    }
    .navBarResponsive {
         width: 100% !important;
    }
    .navBarResponsive .navBarResponsiveSection {
        display: flex;
    }
    .statusText {
        font-size: 9px;
    }

 }
 @media (max-width: 360px) {
   .pcLogo {
        width: 4rem !important;
    }
    .statusText {
        font-size: 8px;
    }
 } 

 .accordion-item{
    border: 0px !important;
 }

 .css-13cymwt-control{
    border-radius: 10px !important;
 }


 .progress-stepper {
    width: 100%;
    overflow: auto;
}
.progress-stepper .nav-pills .nav-link {
    position: relative;
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin: 1rem 1.5rem 1rem 0rem;
    border-radius: 50%;
    background: #d6e1e6;
    border: 2px solid #d6e1e6;
}
.progress-stepper .nav-pills .nav-link:not(:last-child)::before {
    display: block;
    position: absolute;
    content: "";
    border-top: 1px solid #d6e1e6;
    top: 50%;
    width: 2rem;
    left: 1.5rem;
}
.progress-stepper .nav-pills .active {
    background-color: #fff;
    border: 2px solid #1a61b2;
}
.progress-stepper .nav-pills .filled {
    background-color: #2dc8a8;
    border: 2px solid #2dc8a8;
}
.progress-stepper .nav-pills .filled:not(:last-child)::before {
    display: block;
    position: absolute;
    content: "";
    border-top: 1px solid #2dc8a8;
    top: 50%;
    width: 15rem;
    left: 1.5rem;
}
.accordion-steps {
    border-bottom: 1px solid #d6e1e6;
}
