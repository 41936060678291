.header-block-1 {
    padding: 0 !important;
    margin-top: 1.5rem !important;
    min-height: 300px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: url("../../assets/bgs/Banner.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
.text-container-1 {
    margin-top: 0rem !important;

}
.header {
    margin-top: 0rem !important;
}
/* .container.header-img-1 {
    margin: 0rem !important;
} */
.header-1 {
    font-weight: 600 !important;
    font-size: 55px !important;
    line-height: 55px !important;
    color: #3a3285;
    font-family: "Calibri" !important;
    padding: 0.5rem;
}
.header-2 {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 40px !important;
    color: #0093dd;
    padding: 0.5rem;
    font-family: "ErasMediumITC" !important;
}
.profile-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background-image: url("../../assets/bgs/Leadership-bg-2.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;

}

.h4-text-name {
    border-bottom: 3px solid #0093dd;
    padding-bottom: 1rem;
    width: 20%;
    margin-top: 1rem;
}
.profile-img {
    width: 170px;
    height: 170px;
    /* border-radius: 25%; */
}
.individual-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
.individual-text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1.5rem;
    padding-top: 2.5rem;
    width: 60%;

}
.point-logo {
    width: 75px;
    height: 75px;
}
.exp-heading {
    font-size: 22px !important;
    padding-bottom: 0.5rem;
    color: #0093dd;
}
.exp-desc {
    font-size: 16px !important;
    font-family: "Calibri";
    text-align: left;
}
.profile-container {
    margin-top: 1rem;
    background: white;
    padding: 1rem;
    margin: 1.5rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

}
body{
    overflow-x: hidden !important;
}
