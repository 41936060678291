.H-top-button1 {
    width: 241px;
    height: 66px;
    background: #0093DD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    color: #fff;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    outline: none;
}

.H-top-button2 {
    width: 241px;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 14px;
    margin-left: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    outline: none;
}

.H-step1-rightimg {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: -80px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.H-step1-rightimg img {
    width: 400px;
    height: 330px;

}

.H-step1-topimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 133px;
    height: 133px;
    margin-top: -60px;
    border-radius: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #F2F6FF;
}

.H-step1-h6 {
    padding: 50px 0 15px 0;
    /* font-family: ErasMediumITC; */
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
}

.H-step1-nextbtn {
    text-align: center;
}

.H-step1-nextbtn button {
    width: 200px;
    font-family: 'ErasDemiITC';
    text-align: center;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
    margin-left: 12rem;
}

.notifybtn input {
    width: 150px;
    font-family: 'ErasDemiITC';
    border-radius: 15px;
    margin-top: 10px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    padding: 10px;
    outline: none;
}




.H-step1-nextbtn1 button {
    width: 200px;
    font-family: 'ErasDemiITC';
    text-align: center;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
}

.H-step1-nextbtn2 button {
    width: 200px;
    font-family: 'ErasDemiITC';
    text-align: center;
    border-radius: 50px;
    margin-top: 2rem;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
    margin-left: 6rem;
}

.H-children-count {
    z-index: 0;

}

.H-children-count button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: #0093DD;
    color: #fff;
    font-size: 23px;
    /* padding: 10px */
    position: relative;
    bottom: 50px;
    left: 170px;

}

.H-children-counter {
    position: relative;
    bottom: 50px;
    left: 160px;
}

/* ----------DOB--------------- */
.H-enterDOB-row {
    margin-bottom: 30px;
}

.H-enterDOB-p {
    margin-right: 50px;
    width: 80px;
    display: flex;
    align-items: center;
}

/* ----------------------------------------------- */

#ck-button {
    margin-right: 45px;
    /* background-color: #EFEFEF; */
    background-color: #FEFEFE;
    border-radius: 4px;
    /* border: 1px solid #949494; */
    border: 1px solid #AAA2A2;
    /* overflow: auto; */
    width: 151px;
    height: 41px;
    display: inline;
    float: left;
}

#ck-button label {
    float: left;
    width: 151px;
    height: 41px;
    cursor: pointer;
}

#ck-button label span {
    /* text-align: center; */
    /* padding:3px 0px; */
    /* display: block; */
    /* border-radius:4px; */
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 23px;
    color: #9E9E9E
}

#ck-button label input {
    position: absolute;
    right: 1000px;
}

/* #ck-button input:hover+span {
    background-color: #efE0E0;
} */

#ck-button input:checked+span {
    background-color: #0093DD;
    color: #fff;
}

#ck-button input:checked:hover+span {
    background-color: #0093DD;
    color: #fff;
}

/* --------------------------------------------- */

@media (max-width: 576px) {
    .H-top-buttons {
        text-align: center;
    }

    .H-top-button1 {
        margin-top: 20px;
    }

    .H-top-button2 {
        margin-top: 20px;
        margin-left: 0;
    }

    .H-ck-buttons {
        margin-left: 30px;
    }

    #ck-button {
        text-align: center;
        margin-top: 15px;
    }

    .H-children-count {
        position: relative;
        right: 35px;
        /* position: absolute; */
    }

    .H-step1-rightimg {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .H-step1-rightimg img {
        width: 20rem;
        height: 14rem;
        margin-bottom: 25px;

    }

    .container {
        display: flex;
    }

    /* .H-step1-nextbtn{
        width: 100px;
        height: 50px;
    } */

}