.Superdashboard {
  background-color: #edf5fb;
  background-size: 100vh;
  height: 100vh;
}

.bg-secondary {
  background-color: #7d90b2 !important;
}

.bg-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  background-color: #1565d8 !important;
}

.bg-success {
  background-color: #29cc97 !important;
  border-color: #29cc97 !important;
}

.bg-danger {
  background-color: #f12b2c !important;
  border-color: #f12b2c !important;
}

.bg-warning {
  background-color: #fec400 !important;
  border-color: #fec400;
}
.bg-transparent {
  background-color: #edf5fb !important;
  border-color: #edf5fb;
}

.btn-primary {
  background-color: #007bff !important;
  border-color: #007bff !important;
  background-color: #1565d8 !important;
}

.icueTopBarContainer {
  box-shadow: 1rem 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.icueLogo {
  width: 14.5rem;
  /* color: transparent */
}

/* Search Box */

.icueSearchBoxControl {
  background-color: #e9ecef;
  border: 1px solid #ced4da !important;
  border-left: 0 !important;
  font-family: "Calibri";
}

.icueProfilePic {
  width: 2rem;
}

.bi-bell {
  font-size: 1.2rem;
}

.icueNofIndic {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  top: 0;
  left: 50%;
}

.icueNotifyBox {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0;
  width: 15rem;
  /* border: 2px solid red; */
  height: 300px;
  color: #fff;
  background-color: #0093dd;
}
.icueMail {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #3a3285;
}

.icueUserName {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 300;
  /* font-size: 21px; */
  /* line-height: 20px; */
  color: #252733;
}

/* Responsive Side Nav */
.icueRespSideBar {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: cyan; */
}

.icueSideBar {
  position: absolute;
  display: none;
  width: 100%;
  background-color: white;
  transition: all 0.5s ease;
  z-index: 1;
  font-family: "Calibri";
}

/* Side Nav */
/* #backgroundOverlay{
    background-color:transparent;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:block;
} */

/* #icueRespHumburger {
    position: absolute;
} */

#icueCloseIcon {
  display: none;
}

.icueNavBarList {
  padding: 0;
  /* margin: 0; */
  /* display: block; */
  width: 100%;
  list-style-type: none;
  list-style-type: none;
  font-family: "Calibri";
}

.icueNavBarList > li.active {
  list-style-type: none !important;
  text-decoration: none;
  padding-left: 1rem;
  color: #334d6e;
  background: #f4f9ff;
  border-left: 3px solid #334d6e;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-family: "Calibri";
}

.icueNavBarItem {
  position: relative;
  padding: 0.9rem 0;
  padding-left: 1.5rem;
  font-weight: 700;
  border-bottom: 0.5px solid lightgrey;
  font-family: "Calibri";
}

.icueNavBarItem::before {
  position: absolute;
  content: "";
  top: 45%;
  left: 3%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #334d6e;
  /* text-transform: capitalize; */
}

.icueNavDot {
  position: relative;
}
.icueNavDot::before,
.icueNavDotClaim::before {
  position: absolute;
  content: "";
  top: 24%;
  left: 3%;
  width: 5px;
  height: 5px;
}

.icueNavDotClaim {
  position: relative;
}

.icueNavDotClaim::before {
  top: 17%;
}

.icueNavDotSet {
  position: relative;
}

.icueNavDotSet::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  color: transparent;
  background-color: transparent;
}

.icueNavBarLink {
  display: block;
  color: #334d6e;
  text-decoration: none;
  /* border: 2px solid red; */
}

.icueSubNavLink {
  color: #334d6e;
  font-size: 0.8rem;
  font-weight: normal;
  text-decoration: none;
}

/* Add Memeber */

.btnIcon,
.btnIcon2 {
  position: relative;
}

.btnIcon::before,
.btnIcon2::before {
  position: absolute;
  content: "\2192";
  bottom: -30%;
  right: 5%;
  font-size: 3rem;
}

.btnIcon::after,
.btnIcon2::after {
  position: absolute;
  content: "";
  bottom: 49%;
  right: 16%;
  border-width: 3px 12px;
  border-style: solid;
  /* border-color: #007bff; */
  border-color: #1565d8;
}

.btnIcon2::after {
  right: 10.3%;
}

#addModalHeader,
#editModalHeader,
#deleteModalHeader {
  /* background-color: green!important; */
  border-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}

#addModalBody,
#editModalBody,
#deleteModalBody {
  padding-top: 0;
}

.addModalBody .form-group .form-control {
  border: 1px solid #b9c0cc !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\00BB";
  color: black;
  font-size: 1rem;
}

/* LMS Styles */
.icueBgGradient1 {
  background: linear-gradient(to left, #109cf1, rgb(21, 101, 216));
}

.icon1 {
  color: #109cf1;
}

.icueBgGradient2 {
  background: linear-gradient(to left, rgb(41, 204, 151), rgb(36, 175, 36));
}

.icon2 {
  color: rgb(41, 204, 151);
}

.icueBgGradient3 {
  background: linear-gradient(to left, rgb(232, 100, 100), rgb(201, 12, 12));
}

.icon3 {
  color: rgb(232, 100, 100);
}

.icueBgGradient4 {
  background: linear-gradient(to left, rgb(120, 113, 183), rgb(58, 50, 133));
  background: linear-gradient(to left, #885af8, #3a3285);
}

.icon4 {
  color: rgb(120, 113, 183);
  color: #885af8;
}

.icueBgDanger {
  /* background-color: rgb(250,84,87); */
  background-color: #fa5457;
}

.icueTextLight {
  color: rgb(125, 144, 178);
  font-family: "Mulish";
}

.icueBgPrimary {
  background-color: rgb(58, 50, 133);
  background-color: #3a3285;
}

.icueTextPrimary {
  color: rgb(58, 50, 133);
  color: #3a3285;
  font-family: "ErasDemiITC";
}

.icueTextInfo {
  /* color: rgb(21,101,216); */
  color: #0a65ff;
  /* color: #00a0e4; */
}

.icueTextInfoLight {
  color: #109cf1;
  font-family: "ErasDemiITC";
}

.icueTextSuccess {
  color: rgb(36, 175, 36);
}

.icueTextLightDanger {
  color: rgb(250, 84, 87);
}

.icueTextDanger {
  color: rgb(253, 29, 30);
}

.icueTextLigthInfo {
  color: rgb(178, 226, 247);
}

.icueCard {
  border: 2px solid transparent;
}

.icueCard:hover {
  /* cursor: pointer; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 2px solid #109cf1;
}

.icueCard:hover h3:first-child {
  color: #109cf1;
  text-decoration: underline;
}

.icueShadowLight {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.icueShadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.icueBgLightGrey {
  background-color: rgb(235, 239, 242);
}

.icueRounded {
  border-radius: 0.9rem !important;
}

/* Super Admin */

.icueBorBottomPrimary {
  border-bottom: 3px solid #3a3285;
}

.icueBorBottomLightInfo {
  border-bottom: 3px solid #109cf1;
}

.icueBorBottomLightDanger {
  border-bottom: 3px solid rgb(250, 84, 87);
}

#icueLeadInfoForm .form-control,
#icueLeadInfoForm .form-select {
  font-size: small;
}

/* Reset Password */
#icueResetPassEmail::-webkit-input-placeholder {
  text-align: center;
}

/* Firefox 18- */
#icueResetPassEmail:-moz-placeholder {
  text-align: center;
}

/* Firefox 19+ */
#icueResetPassEmail::-moz-placeholder {
  text-align: center;
}

#icueResetPassEmail:-ms-input-placeholder {
  text-align: center;
}

@media screen and (min-width: 567px) and (max-width: 991px) {
  .icueNavBarItem::before {
    position: absolute;
    content: "";
    top: 45%;
    left: 0.7%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #334d6e;
    /* text-transform: capitalize; */
  }

  .icueNavDot {
    position: relative;
  }
  .icueNavDot::before {
    position: absolute;
    content: "";
    top: 24%;
    left: 0.7%;
    /* border-style: solid;
        border-width: 2.5px 2.5px;
        border-color: #334d6e; */
    width: 5px;
    height: 5px;

    /* font-size: 30px; */
  }

  .icueNavDotSet::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    color: transparent;
    background-color: transparent;
  }
}

.fs-7 {
  font-size: 0.8rem;
  font-weight: 500;
}

/* Super Admin RFQ Table */

#icueRfqTable tr th {
  font-size: 0.8rem;
}
#icueRfqTable tr td {
  font-size: 0.9rem;
  font-weight: 500;
}

#icueRfqTable .table > :not(caption) > * > * {
  border-bottom-width: 0 !important;

  /* box-shadow: inset 0 0 0 9999px red !important; */
}

#icueRfqTable .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #edf5fb !important;

  /* background-color: rgb(244,247,252) !important; */
}
#superadmintable,
#superadmintable tr,
#superadmintable td,
#superadmintable th {
  border: 0;
}
#superadmintable tr {
  font-family: "Calibri";
  font-size: 16px;
}

mark {
  background: rgb(166, 166, 220);
  color: black;
}
.highlight {
  background-color: #fff34d;
  -moz-border-radius: 5px; /* FF1+ */
  -webkit-border-radius: 5px; /* Saf3-4 */
  border-radius: 5px; /* Opera 10.5, IE 9, Saf5, Chrome */
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* FF3.5+ */
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Saf3.0+, Chrome */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Opera 10.5+, IE 9.0 */
}

.highlight {
  padding: 1px 4px;
  margin: 0 -4px;
}
#Superdashboard .form-control {
  height: 40px;

  width: 250px;
  border-radius: 3px;
  border: 0.5px solid #b5bbc0;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  background-color: #e9ecef;
  font-family: "Calibri";
  font-size: 18px;
  box-sizing: border-box;
}
/* /Super Admin RFQ Table */
