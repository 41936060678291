/* Stepper */

.rs-steps-item {
    padding-left: 0px!important;
    /* background-color: blue!important; */
   position: relative;
   overflow: hidden;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
   border-color: #350071 !important;
   color: #350071 !important;
   background-color: #fcfaff !important;
}
.rs-steps-vertical .rs-steps-item {
    padding-bottom: 20px;
    /* background-color: red!important; */
}
.rs-steps-item-icon-wrapper {
    position: inherit!important;
   width: 30px;
   height: 30px;
   text-align: center;
   font-size: 16px;
   line-height: 1.75;
   color: #350071!important;

   top: 0;
   left: 0;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-align: center;
   align-items: center;
   border: 1px solid #350071!important;
   border-radius: 50%;
}

item-icon-wrapper {
   border-color: rgb(58, 26, 95)!important;
   color: #350071 !important;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
   border-color: #350071!important;
   color: #350071!important;
}

.icon {
   width: 33px;
   margin-left: -1.3px;
}

