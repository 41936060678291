.section-examination {
  background: #fff ;
//   background: white;
  // padding: 3rem 3rem;
  height: auto;
}
.tbody-section {
  background: #ebeff2;
  // margin: 0px 20px;

  &__main-content {
    padding: 20px 30px;
    h1 {
      text-align: center;
    }
    h1::after {
      content: "";
      display: inherit;
      width: 45%;
      margin: 10px auto;
      border-top: 3px solid black;
      padding-left: 7rem;
    }
    &__examination-information {
      padding: 22px 5rem;
      span {
        color: #f9b776;
        padding-right: 10px;
      }
      &__btn-exam {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}

.examination-block {
  &__table-form {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    background: #fff;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    @media screen and (max-width:706px) {
      display: block;
    }
  }
  &__table-form2 {
    display: flex;
    width: 100%;
    @media screen and (max-width:706px) {
      display: block;
    }
    margin-top: 1px;
    &__question-click {
      width: 25%;
      border: 1px solid #ebeff2;
      background-color: #ebeff2;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        @media screen and (max-width:706px) {
          width: 100%;
        }
      &__btn-pointer {
        padding: 10px 20px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 350;
        margin-right: 12px;
        margin-top: 10px;

        button {
          border-radius: 35px !important ;
          height: 35px !important ;
          width: 35px !important ;
          border: 1px solid black;
          margin: 5px 3px;
          background: #c4c4c4;
          border: 1px solid #c4c4c4;
          color: white;
        }
        .quesactive {
          background: #f9b776 !important;
        }
        .attemp {
          background: #0093dd !important;
        }
        button:hover {
          background-color: #f9b776;
        }
      }
    }

    &__question-click2 {
      width: 75%;
      background-color: #fff;
      @media screen and (max-width:706px) {
        width: 100%;
      }
      &__Answer {
        border-bottom: 2px solid black;
        margin: 0px 20px;
      }
      &__Answer-click {
        margin: 40px 46px;
        font-family: "ErasDemiITC";

        &__A1-btn {
          display: flex;
          margin: 2rem 0px;

          button {
            border-radius: 50% !important ;
            height: 25px !important ;
            width: 25px !important ;
            border: 1px solid black;
            background: #fff;
            border: 1px solid #0093dd;
            color: black;
            font-weight: bold;
            margin-right: 8px;
          }
          button:hover {
            background-color: #0093dd;
            border: 1px solid #0093dd;
            color: #fff;
          }
          .attemp {
            background: #0093dd !important;
            color: #fff;
          }
        }
        &__start-btn {
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          padding: 3px 3.5rem;
          &__clear-selection {
            border: 1px solid red;
            border-radius: 25px;
            background: #fff;
            color: #ff0000;
            padding: 6px 40px;
            font-weight: bold;
          }
          &__select-btn {
            color: #0093dd;
            border: 1px solid #0093dd;
            background: #fff;
            padding: 6px 20px;
            font-size: 1rem !important;
            border-radius: 25px;
          }
        }
      }
    }
  }
  span {
    color: #535252;
    font-family: "ErasDemiITC";
    font-size: 1.5rem !important;
  }
}
.selections {
  margin-top: 3rem;
}
.select-options {
  display: flex;
  margin-bottom: 12px;
  margin-left: 22px;
  &__btn-select {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-top: 2px;
  }
  p {
    // margin: 0px 12px;
    font-size: 14px;
    margin-right: 8px;
    margin-left: 8px;
  }
}
.questionClass {
  font-family: "ErasDemiITC" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 40px !important;
}
.optionClass {
  font-family: "ErasMediumITC" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #656565 !important;
}

.btn-button {
  color: white;
  background-color: #0093dd;
  padding: 6px 40px;
  border-radius: 25px;
  text-align: center;
  margin: auto !important ;
  cursor: pointer;
  font-family: sans-serif;
}
.btn-button:hover {
  background-color: white;
  border: 1px solid #ebeff2;
  color: black;
}

.mr-s {
  margin-right: 10px;
}

.color-blue {
  background: #0093dd;
}

.color-red {
  background: red;
}

.color-grazz {
  background: #f9b776;
}

.result-section {
  text-align: center;
  align-items: center;
  padding: 4rem 0px;
  &__lg-congress {
    padding: 5px 20px;
    &__congratulations {
      color: #0093dd;
      margin-bottom: 20px;
    }
    &__lg-pera-result {
      margin-bottom: 20px;
      color: black;
    }
    &__lg-Questions {
      margin-bottom: 10px;
      font-weight: bold;
      span {
        color: #0093dd;
        font-family: "ErasDemiITC";
      }
    }
    &__lg-Score {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 21px;
      span {
        color: #0093dd;
        font-family: "ErasDemiITC";
        font-size: 21px;
      }
    }
    &__lg-Status {
      margin-bottom: 20px;
      font-size: 18px;
      p {
        font-size: 18px;
        font-family: "ErasDemiITC";
      }
      span {
        color: #0093dd;
        font-size: 18px;
        font-family: "ErasDemiITC";
      }
    }
    &__lg-Download-btn {
      color: #fff;
      background: #0093dd;
      border-radius: 4px;
      padding: 8px 20px;
    }
  }
}
