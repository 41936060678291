.form-wrapper {
    margin: 10px;
  }
  .full-input {
    display: inline-block;
    padding: 0.5px;
    border: 0.5px solid rgb(235, 239, 242);;
    background-color:#F2F2F2;
    box-sizing: border-box;    
    /* width: 240px; */
    height: 2.5rem;
    
  }
  input {
    outline: none;
    border: 1px;
    display:block;
    line-height: 1.2em;
    font-size: 10pt;
    background-color: #F2F2F2;
   height: 0.9rem;
   color: black;
  }
  label {
    
    font-size: 12px;
    color: grey;
    /* font-weight: bold; */
    line-height: 0.5rem;
  }
  ::placeholder{
    color: black;
    font-weight: bold;
    word-wrap: break-word;
  }
  .h6{
    color: grey;
    font-family: 'ErasDemiITC';
  
  }
  #choose-file-label {
    width: 40%;

}
  .accountNavBarList > li.active {list-style-type: none !important;
    text-decoration: none;
    padding-left: 1rem;
    color: #334d6e;
    background: #f4f9ff;
    border-left: 3px solid #334d6e;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
   font-family: 'Calibri';
}

.accountNavBarItem {
    position: relative;
    padding: 0.9rem 0;
    padding-left: 1.5rem;
    font-weight: 700;
    border-bottom: 0.5px solid lightgrey;
    font-family: 'Calibri';
    /* width: 17rem; */
}

/* .accountNavBarItem::before {
    position: absolute;
    content: '';
    top: 45%;
    left:3%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #334d6e;
   
} */
.accountNavBarLink {
  display: block;
  color: #334d6e;
  text-decoration: none;
  /* border: 2px solid red; */
}


  
 