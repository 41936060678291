#ck-button2 {
    margin-top: 20px;
    margin-right: 220px;
    margin-bottom: 30px;
    /* background-color: #EFEFEF; */
    background-color: #FEFEFE;
    /* border: 1px solid #949494; */
    /* overflow: auto; */
    /* width: 151px; */
    /* height: 41px; */
    font-family: 'ErasDemiITC';
    display: inline;
    float: right;
    font-size: 17px;
}

#ck-button1 {
    margin-right: 45px;
    margin-bottom: 30px;
    /* background-color: #EFEFEF; */
    background-color: #FEFEFE;
    border-radius: 4px;
    /* border: 1px solid #949494; */
    border: 1px solid #AAA2A2;
    /* overflow: auto; */
    width: 151px;
    height: 41px;
    display: inline;
    float: left;
}

#ck-button1 label {
    float: left;
    width: 151px;
    height: 41px;
    cursor: pointer;
}

#ck-button1 label span {
    /* text-align: center; */
    /* padding:3px 0px; */
    /* display: block; */
    /* border-radius:4px; */
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 23px;
    color: #9E9E9E
}

#ck-button1 label input {
    position: absolute;
    right: 1000px;
    display: none;
}

/* #ck-button input:hover+span {
    background-color: #efE0E0;
} */

#ck-button1 input:checked+span {
    background-color: #0093DD;
    color: #fff;
}

#ck-button1 input:checked:hover+span {
    background-color: #0093DD;
    color: #fff;
}

.H-step1-nextbtn2 button {
    width: 200px;
    font-family: 'ErasDemiITC';
    text-align: center;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
}

/* .heading-container {
    background-color: red;
    margin-top: 50px;
    margin-left: 250px
}

.heading {
    font-family: 'ErasDemiITC';
    color: #0093DD;
} */

@media (max-width: 576px) {
    #ck-button1 {
        margin-bottom: 10;
        margin-right: 0;
    }

    .ck-button1-all {
        display: flex;
        align-items: center;
        /* justify-content: space-; */
        flex-direction: column;
    }
}


.form-input {
    align-items: center;
    margin-top: 2rem;
    margin-left: 2.1rem;
}

.form-Container {
    margin-left: 6rem;
    margin-top: 2rem;
}

.label {
    margin-left: 20px;
    margin-right: 20px;
}


