.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #fff !important ;
}

.css-1x7skt0 {
   background-color: #fff!important;
}
.css-to4x1n-MuiBadge-badge {
    min-width: 13px!important;
    top: 1px!important;
    right: 3px!important;
    height: 13px!important;
}
.bgWhite{
    background-color: #fff!important;
        max-width: 100% !important;
}

/* @media screen and  (max-width: 1380px) {  */
    #UsuallyPosition {
        position: fixed;
    }
/* } */