.login-card {
    margin: 100px 5%!important ;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 37px;
    text-align: center;
    padding-bottom: 30px;
    position: relative; 
    }
    .alignrow {
      display: flex;
      flex-direction: row;
      justify-content: center ;
  }
    .postext {
      font-family: 'calibri';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height */
      
      text-align: center;
      
      color: #0093DD;
  }
    .poste {
      font-family: 'calibri';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      
      text-align: center;
      
      color: #8f9192;
  }
  .containerStyle {
  width: 100%;
  margin: auto;
  display: block;
  justify-content: space-between;
  }
  .inputStyle {
  height: 55px;
  width: 40px !important;
  border: 0px;
  font-size: 24px;
  border-bottom: 2px solid lightgrey;
  font-family: 'Calibri',sans-serif;
  background-color: #fff!important;
  }
  .bg-light {
  background-color: #fff!important;
  border-radius: 10px !important;
  }
    .remembertextb {
      font-family: 'ERASMEDIUMITC';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #150E42;
      padding-left: 2px;
  }
    .remembertext {
      font-family: 'ERASMEDIUMITC';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: #656565;
      padding-left: 2px;
  }
    .modal-body {
        padding: 0px!important;
    }
    .modal-btn-icon {
        font-size: 30px !important;
        color:  #150E42 !important;
        position: absolute;
        top: 10px !important;   
        right: 30px;        
    }
  
    .headerlog{
        padding: 10px 20px;
        border-bottom: 5px solid #C4C4C4;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .icc{
      font-size: 28px !important;
        color:  #d6d8d6 !important; 
        padding-left: 0px;
        padding-top: 2rem;
    }
    .dishead{
      background: #F5F5F5;
      opacity: 0.66;
      box-shadow: inset 0px -3px 15px rgba(0, 0, 0, 0.07);
      padding: 15px;
      width: 100%;
      text-align: center;
    }
    .i{
      width: 22px;
      margin-right: 10px;
    }
    .disheadb{
      background: #fff;
      padding: 15px;  
      width: 50%;
      text-align: center;
    }
    .modal-content {
    margin: 100px -5%!important ;
    width: 100%!important;
    background: #fff;
    border-radius: 37px;
    /* text-align: center; */
    padding-bottom: 24px;
    position: relative;
    padding-top: 1rem!important; 
    }
    @media only screen and (max-width : 768px )  {
      /* margin: 100px 0%  !important;   */
      .modal-content { 
        margin: 100px 0%!important ;
      }
  }
  
    .Register_Pos_login{
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    color: #150E42;
  
  }
  .modal-block-txt{
    flex: none;
    margin-bottom: 15px!important;
  padding-left: 10px;
  }
    .modal-block-close-btn {
        display: block;
        margin-top: -15px!important;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 9.375rem;
    }
    .modal-block {
      display: block !important;
      flex-direction: column;
    justify-content: center;
    align-items: center;
        padding-left: 2rem;
       
        
    }
    
    .cross-icon{
    position: absolute;
    top:30px;
    right: 20px;
    cursor: pointer;
    
    }
    .login-card img{
    width: 340px;
    height: 60px;
    margin: 30px 0;
    }
    .login-chechbox {
    margin: 20px 0;
    }
    .new-user{
    margin: 10px 0;
    }
    .login-card h3{
    margin: 30px 0;
    }
    .login-form{
    width:50%;
    }
    
    .loginimg{
    width:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    }
    .loginimg img{
    width: 287px;
    height: 279px;
    }
    
    .inputfield{
    width: 350px;
    height: 60px;
    line-height: 50px;
    background: #fafafa;
    font-size: 16px;
    box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    
    
    outline: none;
    margin: 20px 0;
    
    border: none;
    }
    
    .login-form a{
    text-decoration: none;
    }
    .login-btn{
            width: 97%!important;
            height: 50px;
            border: none;
            border-radius: 25px;
            background-color: #0093dd;
            color: #fff;
            font-weight: normal;
            margin: 10px 0px!important;
            margin-left: -8px!important;
            font-family: 'ERASDEMIITC';
            font-size: 1rem;
            margin-top: 10px;
    }
    .social-login{
    width: 380px;
    border:none;
    border-radius: 25px;
    height: 40px;
    color: #fff;
    margin: 10px 0;
    }
    .facebook{
    background-color: #184AD8;
    }
    .google{
    background-color: #D81818;
    }
    
    
    @media (max-width:855px){
    .loginimg{
    display: none;
    }
    .login-form{
    width: 100%;
    }
    }
    .hr{
            width: "60px";
            height: "4px";
            background-color: "#29166f ";
            border: "none";
            margin-left: "45%";
          
    }
  
    .heading {
            font-family: 'ERASDEMIITC';
    }
  
    .align-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    }
  
    .redirect-text {
            cursor: pointer;
            color: rgb(41, 22, 111);
            font-family: 'ERASMEDIUMITC';
    }
    .or-text{
            color: #535252;
            font-family: 'ERASMEDIUMITC';
            font-size: 1rem;  
    }
    .column {
            display: flex;
             flex-direction: column; 
             align-items: center;
    }
  
    .car2-form-groupp {
        position: relative;
        /* margin-bottom: 1rem; */
        margin-right: 2rem;
        width: 270px;
        display: flex;
        flex-direction: column;
      }
      @media only screen and (max-width : 768px )  {
          /* margin: 100px 0%  !important;   */
          .car2-form-groupp { 
              width: 100% !important;
          }
          .car2-form-contl {    width: 100% !important;}
      }
      @media only screen and (min-width : 768px ) and (max-width : 1700px )  {
          /* margin: 100px 0%  !important;   */
          
          .car2-form-contl {    width: 150% !important;}
      }
      
      .car2-form-contl { 
        height: 55px;
        width: 150% ;
        background-color: #fff!important;
        border-radius: 3px;
        border: 0.5px solid lightgrey;
        border-radius: 10px;
        padding-left: 15px;
        outline: none;
        font-family: Calibri,sans-serif;
        font-size: 18px;
      }
     
      .car2-form-contl:hover {
        border: 0.5px solid black;
      }
      
      .car2-form-control-placeholderr {
        position: absolute;
        left: 30px;
        /* bottom: 20px; */
        top: -5px!important;
        background-color: #fff!important;
        padding: 0px 5px 0px 5px;
        margin-left: 8px;
        transition: all 300ms!important;;
        opacity:1;
        transform:translate3d(-10%, -90%, 0)!important;
        pointer-events: none;
        /* z-index: 0; */
      }
      
      .car2-form-contl:focus+.car2-form-control-placeholderr {
        font-size: 95%;
        top: 3px;
        transform: translate3d(-10%, -90%, 0);
        opacity: 1;
        background-color: #fff;
        color: #0093dd!important;
      }
      
      .car2-form-contl:focus {
        border: 1px solid #0093DD;
      }
      .registration-body {
        background-color: #E3EAF8;
    }
    .topstop {
        margin-top: -10px!important ;
    }
    .otp-text {
        font-family: 'ErasMediumITC';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
    
    color: #150E42;
    }
    .registration-card {
        margin:  auto;
        width: 974px;
        background: #fff;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 37px;
        text-align: center;
        padding-bottom: 20px;
        padding-top: 50px;
        position: relative;
    }
    
    .heading {
        font-family: 'ERASDEMIITC';
        color: black;
    }
    .btn {
        font-family: 'ErasDemiITC';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 21px;
        align-items: center;
        text-align: center;
    
        color: #FFFFFF;
    }
    /* Policicue logo */
    
    /* .registration-card img {
        width: 340px;
        height: 60px;
        margin: 30px 0;
    } */
    
    .registration-form {
        width: 50%;
    }
    
    .cross-icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .quantity::-webkit-inner-spin-button, 
    .quantity::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    .inputfield {
        width: 350px;
        height: 60px;
        line-height: 50px;
        background: #fafafa;
        font-size: 16px;
        box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
        border-radius: 5px;
        padding: 0 20px;
        font-size: 16px;
        color: #666;
        border: none;
    }
    
    .signupimg {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .signupimg img {
        width: 652px;
        height: 410px;
    }
    .verify-otp {    
        margin: 20px;
    }
    
    .verifyotp-btn {
        width: 301px;
        height: 46px;
        border: none;
        padding: 10px;
        border-radius: 25px;
        background-color: #0093dd;
        color: #fff;
        font-weight: normal;
        font-size: 1rem;
        font-family: 'ErasDemiITC';
        cursor: pointer;
        /* margin: 20px 100px; */
    }
    .verifyotp-btn:hover{
        color: #fff;
    }
    .resend-otp{
        margin-top: 20px;
    }
    .resend-otp a{
        color: #29166F;
        font-family: 'ERASMEDIUMITC';
    }
    .privacypolicy-chechbox{
        display: flex;
        color: #29166F;
        font-family: 'ERASMEDIUMITC';
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .signup-btn {
        width: 270px;
        height: 50px;
        border: none;
        border-radius: 25px;
        background-color: #0093dd;
        color: #fff;
        font-weight: normal;
        margin: 20px 0;
        font-family: 'ERASDEMIITC';
        font-size: 1rem;
    }
    
    /* .privacypolicy-chechbox{
        margin: 20px 0;
        } */
    
    a {
        text-decoration: none;
    }
    
    @media (max-width:865px) {
        .signupimg {
            display: none;
        }
        .registration-card {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        /* .privacypolicy-chechbox {
            width: 350px;
        } */
        .already-have-account {
            width: 350px;
            font-family: 'ERASMEDIUMITC';
        }
        .verify-otp {
            width: 350px;
            margin: 20px;
        }
        .signupbtn {
            width: 350px;
        }
        .social-login-btn {
            width: 350px;
        }
    }
    @media only screen and (max-width:1024px)
    and (min-width:768px){
        .privacypolicy-chechbox{
            width: 35.625rem;
        }
        p.p-topic-1, .p-topic-2, .p-topic-3{
            width:30rem ;
        }
        .topic-1, .topic-2, .topic-3{
            margin-left: -4rem;
        }
    }

.car2-form-contl{
    margin-bottom: -40px;
}