.raise-ticket-lg {
    background: #fff !important ;
        width: 100%;
        height: 100vh;
}
.main-section {
    padding: 24px 40px;
}

.display_flex {
    display: flex;
    flex-wrap: wrap;
}

.Insurance-input {
    position: relative;
    
}

.Insurance-input label {
    position: absolute;
    top: 8px;
    left: 10px;

} 
.Insurance-input input {
    padding: 9px 0px 0px 5px;
    width: 320px;
    background-color: #EBEFF2;
    border: 1px solid #EBEFF2;
    border-radius: 4px;
    border: none !important ;
    height: 44px;
}
.Insurance {
  width: 320px !important ;
  margin-bottom: 12px;
}

.Insurance-input select {
    padding: 10px 0px 0px 7px;
    width: 320px;
    background-color: #EBEFF2;
    border: 1px solid #EBEFF2;
    border-radius: 4px;
    border: none !important ;
    height: 44px;
    color: black;
    font-weight: bold;
}

.m-r {
    margin-right: 2rem;
}

.m-t {
    margin-top: 2rem;
}
.m-t-s {
    margin-top: 20px;
}

.text-area textarea {
    margin-top: 20px;
    width: 672px;
    border-radius: 4px;
    background-color: #EBEFF2;
    border: 1px solid #EBEFF2;
    padding: 4px 12px;
}

.btn-root button  {
    padding: 8px 6rem;
    border-radius: 25px;
    color: white;
    background: #0093dd
}

.btn-root button:hover {
    background: #fff;
    color: black;
    border: 1px solid #EBEFF2;
    cursor: pointer;
}