.form-container {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}
.right-img-container {
    background-color: "#F4F7FC";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}
.car-step1-img {
    height: 300px;
    width: 300px;
}
.step2-car-nextbtn button {
    background: #0093dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.link-terms{
    text-decoration: underline;
    color: blue;
}
@media only  screen and (max-width:667px)
and (min-width:375px){
   .img {
        margin-top: 10px;
        /* margin-left: -50px; */
        position: relative;
        width: 50%;
    } 
    .modal-btn-size {
        position: static;
        margin-left: 28rem;
        margin-top: -1rem;
    }   
}