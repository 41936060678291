/**************************/

/*     General Styles     */

/**************************/

@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1350px;
    }
}

/**********************/

/*     Navigation     */

/**********************/

/*****************/

/*    Header     */

/*****************/

/*********************/

/*     Basic 3     */

/*********************/

/********************************************/

/*     Different types of car insurance     */

/*********************************************/

.h2-heading-different_types_car_insurance {
    text-align: center;
    font-size: 1.3rem;
    font-family: 'ErasDemiITC';
    background: #FFFFFF;
    margin-top: 5rem;
    /* margin-bottom: 5rem; */
}

h2.h2-header {
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-top: 2.5rem;
    margin-left: 7rem;
}

h2.h2-header2 {
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-top: -2.5rem;
    margin-left: 7rem;
}

h2.h2-header1 {
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-top: 1.5rem;
    margin-left: 6rem;
}

h2.h2-headers {
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-top: 1.5rem;
}

.paragraph-header {
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
    margin-left: 7rem;
    font-family: 'calibri';
    font-style: normal;
    font-weight: normal;
    color: #525253;
}

.paragraph-header1 {
    font-size: 1.1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    margin-left: 6rem;
    font-style: normal;
    font-weight: normal;
    color: #525253;
}

.paragraph-headers {
    font-size: 1.1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    font-family: 'calibri';
    font-style: normal;
    font-weight: normal;
    color: #525253;
}


.car-insurance h2 {
    /* margin-bottom: 1.5rem; */
}

h6 {
    color: #535252;
    font-family: 'ErasDemiITC' !important;
    font-size: 16px !important;
    line-height: 2rem;
    letter-spacing: -0.5px;
    margin-bottom: 0.8rem;
}

.card.diff_car_left {
    margin-left: 11rem;
    margin-right: 2.5rem;
    padding: 1.5rem;
    padding-bottom: 5.7rem;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 400px;
}

.card.diff_car_right {
    margin-right: 14rem;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: none;
    height: 400px;
}

.card_diff_car_left1 {
    margin-left: 11rem;
    margin-right: 2.5rem;
    padding: 1.5rem;
    padding-bottom: 5.7rem;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 545px;
}

@media (max-width: 411px) {
    .car-step1-nav-content {
        width: 90%;
        z-index: 99 !important;
        background: yellow !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0px !important;
        padding: 0 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .card_diff_car_left1 {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 1.5rem;
        padding-bottom: 5.7rem;
        border: none;
        border-radius: 15px;
    }

    .card_diff_car_right1 {
        margin-right: 0px !important;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
        background: #FFFFFF;
        box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: none;
        margin-top: 20px;
    }
}

.card_diff_car_right1 {
    margin-right: 14rem;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    border: none;
    height: 545px;
}

p.p-diff_car {
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

/************************************/

/*             Topic 1              */

/************************************/

.topic-1 {
    margin-top: 4rem;
}

h2.h2-topic-1 {
    color: #525253;
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-left: 6.5rem;
}

p.p-topic-1 {
    margin: 1rem 18rem 0rem 11rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

/************************************/

/*             Topic 2              */

/************************************/

.topic-2 {
    /* margin-top: 3rem; */
}

h2.h2-topic-2 {
    margin: 1rem 18rem 0rem 11rem;
    color: #525253;
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-left: 6.5rem;
}

p.p-topic-2 {
    margin: 1rem 18rem 0rem 11rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

/************************************/

/*             Topic 3              */

/************************************/

.topic-3 {
    /* margin-top: 3rem; */
}

h2.h2-topic-3 {
    /* margin: 1rem 18rem 0rem 11rem; */
    color: #525253;
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-left: 6.5rem;
}

p.p-topic-3 {
    margin: 1rem 18rem 0rem 11rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

/************************************/

/*             Topic 4              */

/************************************/

.topic-4 {
    /* margin-top: 3rem; */
}

h2.h2-topic-4 {
    /* margin: 1rem 13rem 1.8rem 9.7rem; */
    color: #525253;
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-left: 6.5rem;
}

p.p-topic-4 {
    margin: 1rem 13rem 1.8rem 9.7rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

/************************************/

/*             Addons              */

/************************************/

.ex-basic-1.pt-5.pb-5 {
    margin-top: 3rem;
    background: #0093DD1F;
}

.container.custom-extend {
    max-width: 1500px;
    margin-top: 0.5rem;
}

.row.addons {
    margin: 0rem 2rem 0rem 4rem;
}

.p-addon-text {
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-family: 'calibri';
    text-align: justify;
}

.h6-heading-addon {
    color: #525253;
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: -0.5px;
    margin-bottom: 0.8rem;
    font-family: 'ErasDemiITC';
    text-align: left;
}

.h2-heading-addon {
    color: #525253;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2.625rem;
    letter-spacing: -0.4px;
    bottom: 3rem;
    text-align: center;
    position: relative;
    margin: 2rem auto;
    font-family: 'ErasDemiITC';
}

.addon {
    min-height: 23rem;
    margin-bottom: 4rem;
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.card-addons {
    background-color: transparent !important;
    border: none !important;
}

.addon-content {
    /* padding: 2rem; */
}

.card.addon.card-size-1 {
    margin-right: 3.5rem;
}

.card.addon.card-size-2 {
    margin-right: 1.9rem;
    margin-left: 1.7rem;
}

.card.addon.card-size-3 {
    margin-left: 3.5rem;
}

/************************************/

/*    Exclusion in Car Insurance    */

/************************************/

.topic-5 {
    margin-top: 1rem;
}

h2.h2-topic-5 {
    color: #525253;
    font-family: 'ErasDemiITC';
    font-size: 1.3rem;
    margin-left: 6.5rem;
    margin-bottom: 1rem;
}

p.p-topic-5 {
    margin: 0rem -1rem 0.5rem 9.7rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}

.h4-topic-5 {
    color: #525253 !important;
    font-family: 'ErasDemiITC';
    font-weight: 500;
    font-size: 1.27rem;
    line-height: 1.4rem;
    letter-spacing: -0.5px;
    margin-bottom: 0.75rem;
    margin-left: 9.75rem;
}

/*********************/

/*        FAQS       */

/*********************/

.faqs {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.card-faqs {
    border: none;
    border-radius: 20px;
}

.btn-faqs {
    /* padding: 0.75rem !important; */
    font-size: 1.2rem !important;
    border-radius: 0rem !important;
    margin-right: 0rem;
    color: #525253;
    font-family: 'ErasDemiITC';
}

img.tile-faqs {
    margin-right: 1rem;
    margin-top: 0.3rem;
    width: 3%;
}

div#accordionExample {
    width: 100%;
}

.card-faqs {
    border-radius: 10px;
}

.card-bg-1 {
    background: #EFF7FD;
}

.card-bg-2 {
    /* background: #FCFDFF; */
    background: white;

}

img.img-chevron-down-faqs {
    margin-top: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
}

.btn:focus, .btn.focus .btn.hover {
    outline: 0;
    box-shadow: none !important;
    text-decoration: none;
}

.btn-link:hover, .btn-link:hover {
    /* color: #0056b3; */
    text-decoration: none !important;
}

.h3-faqs {
    color: #525253;
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 2.25rem;
    letter-spacing: -0.3px;
    font-family: 'ErasMediumITC';
    margin-left: 3rem;
}

p.p-faqs {
    margin: 1.5rem 19rem 1.5rem 3rem;
    font-family: 'calibri';
    color: #525253;
    font-size: 1.1rem;
}



.p-faqs-points {
    font-family: 'Calibri';
    color: #525253;
    font-size: 1.1rem;
    margin-left: 3rem;
    margin-bottom: 0rem;
}

.p-faqs-points-right {
    font-family: 'ErasMediumITC';
    color: #525253;
    font-size: 1.1rem;
    margin-left: 12rem;
    margin-bottom: 0rem;
}

.h3-faqs-third-party {
    color: #525253;
    font-weight: 700;
    font-size: 1.15rem;
    line-height: 2.25rem;
    letter-spacing: -0.3px;
    font-family: 'ErasMediumITC';
    margin-left: 3rem;
    margin-top: 1.7rem;
}

/******************/

/*     Footer     */

/******************/

/*********************/

/*     Copyright     */

/*********************/

/*************************/

/*     Media Queries     */

/*************************/

@media only screen and (max-width: 480px) {
    .car-step1-nav-content {
        width: 90%;
        /* margin-left: 7.5%; */
        display: flex;
        background: white;
        justify-content: space-between;
        height: 0px !important;
        padding: 28px !important;
        align-items: center;
        padding: 0 105px !important;
        font-size: 10px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        /* flex-wrap: wrap !important;
        /* flex-wrap: wrap !important; */
    }

    /* Header */
    /* Insurance FAQS*/
    .h2-heading-faqs {
        text-align: center;
        font-size: 1.6rem;
    }

    .p-heading-faqs {
        margin-left: 1rem;
        margin-bottom: 3.5rem;
        font-size: 1rem;
    }

    div#accordionExample {
        margin: 0rem
    }

    .btn-faqs {
        font-size: 1rem !important;
    }

    /* Copyrights */
    /*  different_types_car_insurance  */
    .h2-heading-different_types_car_insurance {
        margin-left: 1rem;
        font-size: 1.25rem;
        margin-bottom: 0rem;
    }

    .card.diff_car_left {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .card.diff_car_right {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
    }

    /* Basic 3 */
    /* .car-insurance .image-container {
		margin-bottom: 0rem;
	} */
    /*  Topic 1  */
    h2.h2-topic-1 {
        font-family: 'ErasDemiITC';
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }

    p.p-topic-1 {
        margin: 0rem 0rem 0rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    /*  Topic 2  */
    h2.h2-topic-2 {
        margin: 0rem 0rem 0rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    p.p-topic-2 {
        margin: 0rem 0rem 0rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    /*  Topic 3  */
    h2.h2-topic-3 {
        font-family: 'ErasDemiITC';
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }

    p.p-topic-3 {
        margin: 0rem 0rem 0rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    /* Addons */
    .row.addons {
        margin: 0rem 0rem 0rem 0rem;
    }

    .card.addon.card-size-1 {
        margin-right: 0rem;
    }

    .card.addon.card-size-2 {
        margin-right: 0rem;
        margin-left: 0rem;
    }

    .card.addon.card-size-3 {
        margin-left: 0rem;
    }

    /* Topic 4 */
    h2.h2-topic-4 {
        font-family: 'ErasDemiITC';
        font-size: 1.25rem;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }

    p.p-topic-4 {
        margin: 1rem 0rem 0rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    /*  Topic 5  */
    h2.h2-topic-5 {
        font-family: 'ErasDemiITC';
        font-size: 1.25rem;
        margin-left: 0.5rem;
        margin-bottom: 1.4rem;
    }

    .h4-topic-5 {
        color: #525253;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.4rem;
        letter-spacing: -0.5px;
        margin-bottom: 0.75rem;
        margin-left: 0.5rem;
    }

    p.p-topic-5 {
        margin: 0rem 0rem 0.5rem 0.5rem;
        font-family: 'ErasMediumITC';
        color: #525253;
        font-size: 1.1rem;
    }

    /*  FAQS  */
    .h3-faqs {
        margin-left: 0rem;
    }

    p.p-faqs {
        margin: 0rem 0rem 1.5rem 0rem;
    }

    .h3-faqs-third-party {
        margin-left: 0rem;
    }
}

/* Min-width 768px */

@media (min-width: 768px) {
    /* Header */
    /* end of header */
    /* Extra Pages */
    /* end of extra pages */
}

/* end of min-width 768px */

/* Min-width 992px */

@media (min-width: 992px) {
    /* General Styles */
    /* end of general styles */
    /* Navigation */
    /* end of navigation */
    /* Header */
    /* end of header */
    /* Details 2 */
    /* end of details 2 */
    /* Details 3 */
    /* end of details 3 */
    /* Copyright */
    /* end of copyright */
}

/* end of min-width 992px */

/* Min-width 1200px */

@media (min-width: 1200px) {
    /* General Styles */
    /* end of general styles */
    /* Header */
    /* end of header */
    /* Details 2 */
    /* .car-insurance .image-container {
        margin-right: 0rem;
        margin-left: 5.15rem;
        margin-top: 3rem;
	}

	.car-insurance .text-container {
        margin-top: 0rem;
        margin-right: 0rem;
        margin-left: 7rem;
	} */
    /* end of details 2 */
    /* Details 3 */
    /* end of details 3 */
}

/* end of min-width 1200px */

/* -------my Styles------- */

.step1-car-btn {
    width: 210px;
    font-family: 'ErasDemiITC';
    height: 70px;
    border-radius: 14px;
    transition: 0.3s;
    color: #949494;
    background: #fff;
    border: 0.3px solid #949494;
    /* border: none; */
    font-size: 20px;
    padding: 10px;
    outline: none;
}

/* .step1-car-btn:hover {
    background: #0093DD;
    color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
} */

/* .car-step1-nav-content a:hover{
	color: #29166F;
} */

.car-step1-img {
    Width: 260px;
    Height: 232px;
    padding-top: 30px;
    padding-left: 30px;
    /* padding-bottom: 0px; */
}

.car-step1-nav {
    margin-top: -10px;
    /* background: #29166F; */
    height: 64px;
    display: flex;
    align-items: center;
}

.car-step1-nav-content {
    padding: 0 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 90%;
    height: 74px;
    top: 60px;
    z-index: 99;
    background: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
}

.col-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-content {
    text-decoration: none;
}

.scroll-link {
    font-family: 'ErasDemiITC';
    cursor: pointer;
    color: black;
}

.scroll-link :hover {
    color: #3eace3;
    text-decoration: none !important;
}

.wrapper {
    width: 100%;
    height: 100%;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alternative {
    width: 283px;
    height: 302px;
}

.row-container {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    flex-wrap: wrap;
}
@media only  screen and (max-width:1024px)
and (min-width:768px){
    .card_diff_car_left1 {
        margin-left: 9rem;
        margin-right: 9rem;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: 545px;
    }
   
    .card_diff_car_right1 {
        margin-right: 9rem;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
        background: #FFFFFF;
        box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: none;
        height: 545px;
        margin-left: 9rem;
        margin-top: 22px;
    }
    
    .card.addon.card-size-3 {
        margin-left: 0.5rem;
        margin-right: 2rem;
    }
    .card.addon.card-size-2 {
        margin-right: 1.2rem;
        margin-left: 0.7rem;
    }
    .card.addon.card-size-1{
        margin-right: 2rem;
    }
    .card.diff_car_right {
        margin-left: 9rem;
        margin-right: 9rem;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
        background: #FFFFFF;
        box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        border: none;
        height: 545px;
        margin-left: 9rem;
        margin-top: 22px;
    }
    .modal-btn-size {
        position: static;
        margin-left: 28rem;
        margin-top: -1rem;
    }
}