.form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    width: 270px;
    display: flex;
    flex-direction: column;
}


.form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
  }

  .form-control {
    height: 50px;
    width:  220px;
    border-radius: 3px;
    border: 0.5px solid #0093DD;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
 
  /* .form-control:hover {
    border: 0.5px solid black;
  } */
  
  .form-control-placeholderr {
    position: absolute;
    left: 30px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    pointer-events: none;
    /* z-index: 0; */
  }
  
  .form-control:focus+.form-control-placeholderr, 
  .form-control:valid+.form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }