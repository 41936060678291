.products-card {
    display: block;
    justify-content: center;
    margin-top: 10px;
}
 a{
    padding: 0 !important;
}
.products {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    text-align: left;
    /* text-decoration: none; */
    /* align-items: center; */
    /* padding: 30px 50px; */
    border-radius: 5px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.item {
    margin-left: 10px;
    padding: 0.5rem;
    font-family: "ErasMediumITC";
}
.item:hover{
    background: #F2F6FF;
    cursor: pointer;
    border-radius: 15px;

}

div ul h4 {
    color: #3a3285;
    text-align: left;
    /* font-family: Eras Medium ITC; */
    font-family: "ErasBoldITC";
}

.products-li {
    list-style: none;
    margin: 30px 0;
    font-size: large;
    text-align: left;
}

ul li a {
    color: #656565;
    transition: 0.2s;
    text-decoration: none;
}

ul li a:hover {
    color: #0093dd;
    text-decoration: none;
}
.span-text{
    font-size: 14px !important;
    color: black !important;
}
/* this.state={
    count:0;
}
Increment (){
    this.setState({
        count:this.state.count+1
    })
}
render(){
    return(
        <h1>{this.state.count}</h1>
        <button onClick={this.Increment}>increment</button>
    )
} */
/* console.log(null == undefined)  */

/* var x=10;
a();
b();
console.log(x);
function a(){
    var x=1000
    console.log(x)
}
function b(){
    var x=1
    console.log(x)
} */
/* const a;
console.log(a)
a=10; */

/* let a;
console.log(a);
a=10; */
