  .react-tabs {
    display: flex;
    margin-left: -30px;
    color: black;
    background: white;
  }
  
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 5rem;
    color: white;
    background: white;
    border-right: 1px solid #e7e6f1;
  }
  
  .react-tabs__tab {
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #29166f;
    background: white;
    border-top: 1px solid #e7e6f1;
    border-bottom: 1px solid #e7e6f1;
    box-shadow: 1px 2px 1px #e7e6f1;
  }
  
  .react-tabs__tab--selected {
    background: white;
    border-color: #e7e6f1;
    border-left: 4px solid #29166f;
    color: #29166f;
    font-weight: bold;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .react-tabs__tab {
    padding-left: 24px;
  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  
  .panel-content {
    text-align: center;
  }
  