/* ------input field-------- */
.car3-form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    width: 270px;
    display: flex;
    flex-direction: column;
  }
  
  .car3-form-controll {
    height: 55px;
    width: 270px;
    border-radius: 3px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
  
  .car3-form-controll:hover {
    border: 0.5px solid black;
  }
  
  .car3-form-control-placeholderr {
    position: absolute;
    left: 7px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    /* z-index: 0; */
  }
  
  .car3-form-controll:focus+.car3-form-control-placeholderr, 
  .car3-form-controll:valid+.car3-form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }
  
  .car3-form-controll:focus {
    border: 1px solid #0093DD;
  }
/* ------end input field-------- */

.step3-car-insurance {
    /* height: 420px; */
    background-color: #fff;
    margin: 70px 0 0 1.5%;
    width: 97%;
    padding-bottom: 50px;
}

.car-step3-img {
    background-color: #F4F4F4;
    padding: 10px;
    width: 102.03px;
    height: 70.3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin: 10px 4px;
    margin-left: 1rem;
    transition: 0.3s;
}

.car-step3-img:hover {
    background-color: #0093DD;
}

.step3-top-img {
    background-color: #F2F6FF;
    width: 128px;
    height: 128px;
    padding: 40px 10px;
    border-radius: 100%;
    margin: -50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.step3-car-nextbtn {
    text-align: center;
    font-family: 'ErasDemiITC';
    width: 200px;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
}

.step3-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.step3-img img {
    width: 27rem;
    height: 21rem;
}

.autocomplete {
    padding-right: 80px;
    width: 355px;
    height: 30px;
    border-radius: 10px;
    margin-top: 50px;
}

@media (max-width: 576px) {
    .car-step3-imgg{
        
        padding-left: 10px;
    }
    
    .step3-autocomplete-row{
        margin-left: 4%;
    }
    .step3-img{
        text-align: center;
    }
    .step3-img img {
        width: 22rem;
        height: 16rem;
        margin-top: 25px;
    }
}
@media (max-width: 768px){
    .car-step3-img{
        margin-left: 1.52%;
    }
    .step3-img{
        text-align: center;
    }
    /* .row{
        margin-right: 0;
    } */
    .step3-autocomplete-row{
        padding-left: 33px;
    }
    .step3-img img {
        width: 22rem;
        height: 16rem;
        margin-top: 35px;
    }

}
/* @media (min-width: 992px){
    .step3-img{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 50px;
        
        height: 300px;
    }
    .step3-img img {
        width: 22rem;
        height: 16rem;
        margin-top: 35px;
    }
} */