.form-container {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}
.right-img-container {
    background-color: "#F4F7FC";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}
.car-step1-img {
    height: 300px;
    width: 300px;
}
.step2-car-nextbtn button {
    background: #0093dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.link-terms{
    text-decoration: underline;
    color: blue;
}

@media (max-width:480px) {
    .textPrimary{
        max-width: 90%;
    }
    .textSecondary{
        width: 280px;
        margin-left: 6rem;
    }
    .accordion-header-1 button{
        font-size: 1rem !important;
        /* padding: 0;  */
    }
    .accordion-header-2 button{
        font-size: 1rem !important;
        /* padding: 0;  */
    }
}