.h1-heading {
    font-size: 3rem;
    font-family: "ErasDemiITC";
    color: #3A3285;
}

.sub-heading {
    font-family: 'ErasMediumITC';
    color: #0093DD;
    margin-top: 1rem;
    font-size: 1.5rem;
}

.notifybtn input {
    width: 150px;
    font-family: 'ErasDemiITC';
    border-radius: 15px;
    margin-top: 10px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    padding: 10px;
    outline: none;
}


.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

img.img-fluid.img-place {
    max-width: 100%;
    height: 28.5rem;
    margin-right: 3.7rem;
    margin-top: -0.45rem;
}

.footer-image {
    width: 100%;
    margin-top: -5.5rem;
}

.address {
    display: flex;
    align-items: baseline;
    margin-left: 9rem;
}

.paragraph {
    color: #0093DD;
    font-family: 'ErasMediumITC';
    font-weight: bold;
    font-size: 15px;
}
.subParagraph {
    font-family: 'ERASMEDIUMITC';
    margin-left: 5px;
    color: #0093DD;
}
@media only  screen and (max-width:1024px)
and (min-width:768px){
    
    .image-container{
        margin-top: 4rem;
    }
   
}