.connecttopay{
    background-color: #edf5fb;
}
.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    background-color: #edf5fb;
}

    .center-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;
      }
      .loader {
        font-size: 5px;
        margin: 50px auto;
        text-indent: -9999em;
        width: 11em;
        height: 11em;
        border-radius: 50%;
        background: #edf5fb;
        background: -moz-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
        background: -webkit-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
        background: -o-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
        background: -ms-linear-gradient(left, #000000 10%, rgba(255, 255, 255, 0) 42%);
        background: linear-gradient(to right, #000000 10%, rgba(255, 255, 255, 0) 42%);
        position: relative;
        -webkit-animation: load3 1.4s infinite linear;
        animation: load3 1.4s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }
      .loader:before {
        width: 50%;
        height: 50%;
        background: black;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
      }
      .loader:after {
        background:#edf5fb;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      @-webkit-keyframes load3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes load3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      