
.navbar .navbar-brand {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.navbar .nav-items .nav-links {
    padding-top: 1.2rem;
    color: #3A3285;
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 15px;
    font-weight: 500;
    font-family: 'ErasMediumITC';
    margin-right: 0.8rem;
    margin-left: 15px;
}

img.img-customer-cares {
    margin-right: 13px;
    color: #29166F;
    width: 15px;
}


@media (min-width: 992px) {

    .navbar .navbar-nav {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    .navbar .navbar-nav {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
    }
.navbar {
    padding-top: 0px;
    background-color: transparent;
    border-bottom: none;
    transition: all 0.2s;
}

.navbar {
    background-color: #f1f9fc;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding: 0rem 1rem;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
}