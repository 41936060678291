.bg_image_container {
    position: relative;
    text-align: center;
    color: white;
  }

.centered {
    position: absolute;
    top: 40%;
    left: 15%;
    color: #3A3285;
    font-family: 'ErasDemiITC';
    font-size: 5vmin;
  }

  .heading {
    font-family: 'ErasDemiITC';
    color: #3A3285;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .text {
    font-family: 'Calibri';
    color: #949494;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .points {
    font-family: 'Calibri';
    color: #949494;
    margin-left: 2rem;
  }

  .row_container {
    display: flex;
    align-items: center;
    margin-left: 2rem;
  }

  .footer_text {
    font-family: 'ErasDemiITC';
    color: #0093DD;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .logo_container {
    display: grid;
    justify-items: center;
    align-items: center;
  }
  .licence {
    max-height: 100%;
    max-width: 80%;
    margin: 2rem;
  }

  @media (max-width:1024px) {
    .licence{
    margin-left: 3rem;

    }
  }
