.main-div {
    overflow-x: hidden;
}
.paragraph-header {
    margin-left: 0;
}
.why-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
h2.h2-header {
    margin-left: 0;
}
.left-container {
    background: rgba(0, 147, 221, 0.12);
    max-width: 100%;
    height: 27.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.left-block {
    width: 60%;
    position: absolute;
    left: 6rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.right-container {
    max-width: 100%;
    height: 24.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.right-block {
    width: 60%;
    position: absolute;
    left: 13rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.right-inner-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-mission {
    width: 50%;
    position: absolute;
    top: 2rem;
    border-radius: 50%;
    left: -10rem;
}
.h4-text {
    border-bottom: 3px solid #0093dd;
    width: 100px;
    padding-bottom: 1rem;
}
.h4-gap {
    margin-bottom: 0rem !important;
}

.span-texts {
    font-size: 16px;
    line-height: 30px;
    color: #949494;
    margin-top: 1rem;
    text-align: left;
    font-family: "Calibri" !important;
}
.team-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/bgs/Leadership-bg-2.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 30rem;
}
.person-block {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 260px;
    height: 300px;
    background: #ffffff;
    border: 1px solid #f3f2f2;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(202, 205, 219, 0.42);
    border-radius: 15px;
    margin: 0 auto;
    cursor: pointer;
}
.img-person {
    width: 35%;
    border-radius: 50%;
    margin-top: -3rem;
    position: absolute;
    top: 0;
}
.faqs {
    padding-top: 1rem !important;
    padding-bottom: 4rem !important;
}
.related-articles {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.partners {
    padding-bottom: 1rem !important;
}
.leader-ship {
    border-bottom: 3px solid #0093dd;
    padding-bottom: 0.5rem;
}
.h4-name {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 0 !important;
}
.span-desc {
    font-size: 0.75rem;
    text-align: center;
    width: 90%;
    padding: 1rem;
    color: #949494;
    font-family: "Calibri" !important;
}

.testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/bgs/testimonials-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 25rem;
    position: relative;
}

.layer {
    background-color: rgb(17, 45, 87, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-testimonial {
    color: white;
}

.testimonial-card {
    width: 450px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 450px) {
    .span-texts{
        max-width: 180px;
    }
    .right-block {
        width: 100%;
    }
    .img-mission {
        left: 0rem;
    }
    .right-container {
        flex-direction: column;
    }
    .person-block {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}
@media(max-width:1024px)
{
    .img-mission {
        width: 50%;
        position: relative;
        top: 2rem;
        border-radius: 50%;
        
    }
    .right-block{
        margin-left: 7rem;
        width:50%;
    }
    /* h2.h2-header{
        margin-left: 2rem;
    } */
    
   
}
@media(max-width:667px){
    .img-mission {
        width: 50%;
        position: relative;
        top: -2rem;
        border-radius: 50%;
        
    }
    .right-block{
        margin-left: -1rem;
        width:50%;
        margin-top: -7rem;
    } 
}
