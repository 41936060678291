.H-toprenew-button2 {
    width: 241px;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 14px;
    margin-right: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    outline: none;
    color: #949494;
    font-family: 'ErasDemiITC';
}

.H-toprenew-button1 {
    width: 241px;
    height: 66px;
    background: #0093DD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    color: #fff;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    outline: none;
}