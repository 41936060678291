.VD-top-img {
    background-color: #F2F6FF;
    width: 135px;
    height: 135px;
    border-radius: 100%;
    margin: -50px;
}

.VD-img {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    /* background-color: aqua;*/
    
}
.VD-img img{
    width: 410.48px;
    height: 233.55px;
}
.VD2-img {
    display: flex;
    align-items: center;
    justify-content: left;
    /* background-color: aqua;     */
    
}
.VD2-img img{
    width: 410.48px;
    height: 233.55px;
}
.VD-cwrn{
    color: #29166F;
    cursor: pointer;
    text-decoration: underline;
}
.VD-cwrn:hover{
    text-decoration: none;
    color: #29166F;
}
/* -------input box------- */
.form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    width: 270px;
    display: flex;
    flex-direction: column;
    
  }
  .form-controll{
    height: 55px;
    width: 270px;
    border-radius: 3px;
    border:0.5px solid lightgrey;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
  }
  .form-controll:hover{
    border:0.5px solid black;
  }
  
  .form-control-placeholderr {
    position: absolute;
    left:15px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    /* z-index: 0; */
  }
  
  .form-controll:focus+.form-control-placeholderr,
  .form-controll:valid+.form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
    
    
  }
  .form-controll:focus{
    border:1px solid #0093DD;
  }
  h2 {
    font-size: 25px;
    line-height: 50px;
    font-family: 'ErasDemiITC';
    color: black;
}