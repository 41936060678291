.step4-car-insurance {
    background-color: #fff;
    margin: 65px 0 0 1.5%;
    width: 97%;
    padding-bottom: 1px;
}

/* .step4-container{

} */

.step4-img {
    display: flex;
    margin-top: 2rem;
}

.step4-img img {
    width: 435px;
    height: 337px;
}

.bike4-img img {
    width: 400px;
    height: 250px;
    margin-top: 4rem;
}

.step4-car-nextbtn {
    /* style={{ textAlign: 'center',paddingBottom:'50px' }} */
    text-align: center;
    margin-left: 12rem;
}

.step4-car-nextbtn button {
    text-align: center;
    font-family: 'ErasDemiITC';
    width: 200px;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #0093DD;
    color: #fff;
    border: 0.3px solid #949494;
    font-size: 17px;
    padding: 10px;
    outline: none;
}

@media (max-width: 576px) {
    .step4-img {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .step4-img img {
        width: 20rem;
        height: 14rem;
        margin-bottom: 25px;
    }
}