.form-container {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}
.right-img-container {
    background-color: "#F4F7FC";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
}
.car-step1-img {
    height: 300px;
    width: 300px;
}
.step2-car-nextbtn button {
    background: #0093dd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.h6-text-heading{
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.p-diff_car{
    text-align: justify;
}
.img-cls{
    width: 75px;
    height: 75px;
}
.col-cls{
    margin-top: 2rem;
}

.link-terms{
    text-decoration: underline;
    color: blue;
}
@media only  screen and (max-width:1024px)
and (min-width:768px){
    .card.diff_car_left {
        margin-left: 9rem;
        margin-right: 9rem;
        padding: 1.5rem;
        padding-bottom: 2.5rem;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        height: 545px;
    } 
   
}