.header-block {
    background: url(/src/components/HomePage/Group\ 154.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.h4-text-header{
    margin-left: 4rem;
}

.des-data{
    font-size: 12px;
    font-family: "Calibri";
}