#dtBasic_Examples  tr{
    height: 5rem;
}

#dtBasic_Examples th {
    background-color: #fff;
    font-family: "Calibri";
    font-weight: bold;
    color: black;
    font-size: 14px;
    width: 148.3px !important;
}
label{
    font-weight: 400;
}