/* Breadcrumb__Section */
.Breadcrumb__Section 
{
    padding: 6rem 0 0rem 0;
    background: #fff;   
}
.main-div{
    background: #fff;   
}
.breadcrumb
{
    padding: 12px 0px;
    background-color: #fff;
    margin-bottom: -0.6rem;
}

/* Registration__Section */
.Registration__Section
{
    padding: 0 0 3rem 0;
}
.top
{
    margin-top:-1.5rem!important;
}
@media only screen and (max-width : 568px )  {
    .Register__Pos__Heading_left
    {
       padding:5px;
    }
    .Register__Pos__Sub__Heading{
        padding:5px;
    }
    .Register__Pos__para{
        padding-left: 10%;
    padding-right: 10%;
    }
}

@media only screen and (max-width : 468px )  {
    .Register__Pos__para{
                    padding-left: 5px;
                padding-right: 5px;
    } 
    .terms {
        max-width: 300px !important;
    }
}
.Register__Pos__Heading_left
{
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #29166F;
    text-transform:capitalize;
}
.Register__Pos__Heading
{
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #150E42;
}
.Register__Pos__para
{
    font-family: 'Calibri';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #949494;
}

.Register__Pos__Heading_left span
{
    margin-left: 0.6rem;
    text-transform: none;
    font-weight: 500;
    font-size: 1.72rem;
    color: #0093dd;
    font-family: 'ErasMediumITC';
}
.Register__Pos__Heading span
{
    margin-left: 1rem;
    text-transform: none;
    font-weight: 500;
    font-size: 1.72rem;
    color: #0093dd;
    font-family: 'ErasMediumITC';
}

.Register__Pos__Sub__Heading
{
    font-family: 'Eras Medium ITC';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    color: #150E42;
    padding-top: 0.5rem;
}

/* Icon__Border */
.Icon__Border
{
    border: 1px dotted #525253;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: dashed;
}
.Register__Pos__Head{
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-size: 36px;
    line-height: 41px;

/* theme color 1 */

color: #29166F;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Icon__Border i
{
    font-size: 30px;
    color: #0093dd;
}

.Icon__Title
{
    margin-top: 10px;
    font-family: 'ErasDemiITC'
;
    font-style: normal;
    font-weight: 600;
    color: #150E42;
    font-size: 26px;
    line-height: 30px;
}

.Icon__Sub__Title
{
    color: #525253;
    font-family: 'ErasMediumITC';
    font-weight: bold;
}

/* Continue__Button */
.Continue__Button
{
    /* font-size: 20px;
    font-weight: bold; */
    font-family: 'ErasMediumITC';
}

.Resend_Code
{
    margin-top: 10px;
    text-align: center;
}
.radius
{
    background: #fff;   
    margin: 30px;
    border-radius: 15px;
}
.accept
{
    font-family: 'ErasDemiITC';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #8B8B8B;
}
.TandC
{
    font-family: 'Eras Medium ITC';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #0093dd;
}
label{
    color: #0093dd;
padding-left: 0rem !important;
}
.register-form-control-placeholderr {
    position: absolute;
    left: 30px;
    bottom: 35px;
    top: 10px!important;
    background-color: #fff!important;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms!important;;
    opacity:1;
    transform:translate3d(-10%, -90%, 0)!important;
    pointer-events: none;
    /* z-index: 0; */
  }
  .car2-form-contl:focus+.register-form-control-placeholderr {
    font-size: 95%;
    top: 3px;
    transform: translate3d(-10%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd!important;
  }

  @media only screen and (max-width : 768px )  {
    .terms {
        max-width: 300px !important;
    } 
    .breadcrumb
{
    padding: 12px 0px;
    margin-top: 30px;
    background-color: #fff;
    margin-bottom: -0.6rem;
}
   }