/* ------input field-------- */
.health-form-groupp {
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 2rem;
    width: 270px;
    display: flex;
    flex-direction: column;
  }
  
  .health-form-controll {
    height: 55px;
    width: 240px;
    border-radius: 3px;
    border: 0.5px solid lightgrey;
    border-radius: 10px;
    padding-left: 15px;
    outline: none;
    font-family: Calibri,sans-serif;
    font-size: 18px;
  }
  
  .health-form-controll:hover {
    border: 0.5px solid black;
  }
  
  .health-form-control-placeholderr {
    position: absolute;
    left: 10px;
    /* bottom: 20px; */
    top: 15px;
    padding: 0px 5px 0px 5px;
    margin-left: 8px;
    transition: all 300ms;
    opacity: 0.5;
    /* z-index: 0; */
  }
  
  .health-form-controll:focus+.health-form-control-placeholderr, 
  .health-form-controll:valid+.health-form-control-placeholderr {
    font-size: 95%;
    top: 10px;
    transform: translate3d(-5%, -90%, 0);
    opacity: 1;
    background-color: #fff;
    color: #0093dd;
  }
  
  .health-form-controll:focus {
    border: 1px solid #0093DD;
  }
/* ------end input field-------- */