/* .quote-data {
    width: 137px !important;
} */

#dtBasicExamples  tr{
    height: 5rem;
}

#dtBasicExamples th {
    background-color: #fff;
    font-family: "Calibri";
    font-weight: bold;
    color: black;
    font-size: 14px;
    width: 173px !important;
}

    .full-input {
        display: inline-block;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-right: 6px;
        border: 0.5px solid rgb(235, 239, 242);
        background-color: #F2F2F2;
        box-sizing: border-box;
        height: 3.5rem;
    }
