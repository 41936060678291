.dataTables_wrapper .dataTables_filter {
    float: revert!important;
    margin-top: -30px;
    display: none;
}
.tbody-section-agreement{
    background: #ebeff2;
    padding: 5px 5px;
    margin: 10px 15px;
  
}
.teeptext {
    border:2px solid cornflowerblue;
    width:100px;
    border-radius:30px;
    text-align:center;
    background-color:cornflowerblue;
    color:white
}
.Intro h2{
    text-align:center;
color: #525253;
font-family: 'ErasDemiITC';
}

p.solid {border-style: solid;
    height: 120px;
    width: 120px;
    border-color:#94C5F4;
    font-family:Calibri;
    color:#055FB7;
    text-align:center;
    font-size:14px;
    border-radius:11px;
    font-weight: bold;
    padding: 0rem 1rem;
    line-height: 1rem;
}
    p.small{
        border-style:solid;
        border-color:white;
        height: 85px;
        width: 35px;
        background:white;
        margin-top:-2rem;
        margin-left:5.5rem;
        text-align:center;
        
        }
        p.small1{
        border-style:solid;
        border-color:white;
        height: 43px;
        width: 75px;
        background:white;
        margin-top:-8.6rem;
        margin-left:2.7rem;
        text-align:center;
        
        font-family:'ErasDemiITC';
        color:#252733;
        }
        .step1, .step2, .step3, .step4, .step5{
            float:left;
                      width:160px;
                      height:160px;
                      background-color:transparent;
                      border:transparent;}
                    //   span {
                    //     color: #94C5F4;       
                       
                    //     margin-top:-6rem;
                    //     margin-left:7.3rem;
                        
                    //   }

  .my-button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 100px;
    outline: none;
    background: #808283;
    color: white; 
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  .my-button:hover {
    background: #eb2f06;
  }
 
.agreement  {
    margin-top: 2.5rem;
    &__btn-agreement {
        button {
            background-color: cornflowerblue;
            padding: 2px 14px;
            border-radius: 25px;
            font-size: 14px;
            color:#fff;
            font-weight: 500;
            span {
                color: #fff;
                margin-right: 5px;
                font-size: 20px;
            }
        }
    } 
    &__btn-choose-agreement {
        label {   
        background-color: #e9ecef;
        border: 1px solid #e9ecef;
        padding: 12px 12px !important;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 10px;
        color: #000 !important ;
        line-height: 15px;
    }
    } 
    &__btn-choose-file {
        button {
            background: none;
            margin-top: 4px;
            margin-right: 6px;
        }
    }
    &__btn-choose-upload {
        button {
            background-color: cornflowerblue;
            border-radius: 25px;
            padding: 5px 12px;
            color: #fff;
            font-weight: 500;
        }
    }
}

.btn-download a {
    background-color: #fff;
    border: 1px solid cornflowerblue;
    color: cornflowerblue !important ;
    border-radius: 25px;
    padding: 5px 14px !important ;
    font-Family: "Calibri";
}
table#dtBasicExample * {
    font-family: 'Calibri';
}
@media (max-width:768px) { 
    .btn-download a {
        padding: 2px 6px !important;
        font-Family: "Calibri";
        margin-top: 2px;
    }
}
.btn-download a:hover {
    background-color: cornflowerblue;
    color: black !important ;
}

.sorting-num {
    padding: 10px 2rem !important ;
}

@media (max-width: 520px)  {
    .sorting-num {
        padding: 0rem !important ;
        width: 100% !important ;
    }  
    #dtBasicExample {
        padding: 0rem 0rem !important ;
    }   
    #dtBasicExample td {
        padding: 0rem !important ;
    }
    .row-top {
        margin-top: 4rem;
    }
    .Intro h2 {
        font-size: 20px;
    }
}

// .sorting-num1 {
//   s

.agreementFile {
    font-size: 16px !important;
    font-weight: normal !important;
    padding: 3px 12px !important;
    margin-top: 2px;
    border-radius: 25px !important;
    background-color: #0795de !important;
    cursor: pointer;
    color: #fff  !important;
}




@media (max-width: 1380px)  { 
    .useBreadCrumb {
        margin-top: 4rem;
    }
 }
 @media (max-width: 999px)  { 
    .useBreadCrumb {
        margin-top: 8rem;
    }
}

@media (max-width: 768px)  { 
    .row-top {
        margin-top: 4rem;
    }
    .useBreadCrumb {
        margin-top: 0rem;
    }   
}



