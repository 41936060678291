.MyCertificate {
  padding: 4rem 5rem;
  &__certificate {
    background-color: #e9ecef;
    padding: 2rem 4rem;
    margin-top: 2rem;
    text-align: center;
  }
}
.mainBody {
  padding: 50px 50px 0px;
}
.main {
  padding: 20px;
}
.main-inner {
  padding: 10px;
}
.inner-bl {
  padding: 30px;
}
.policicueLogo {
  text-align: center;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.border-1 {
  border: solid 1px #000;
}

.inner-bl h1 {
  margin-bottom: 0;
  padding-bottom: 0;
  line-height: 1;
}
.inner-bl p.companyAddress {
  line-height: 2;
}
.inner-bl .pp-img {
  width: 100px;
  height: 120px;
  margin-left: 30px;
}
.inner-bl .pp-img img {
  width: 100%;
  height: 100%;
}
.pull-right {
  float: right;
}
.clearfix {
  clear: both;
}

.mid-blk {
  margin-top: 40px;
  line-height: 2;
}

@media print {
  .to-be-printed {
    padding: 10px 20px!important;
  }
  .to-be-printed .container {
    height: calc(100vh + 240px);
  }

  .to-be-printed .p_user_img,
  .to-be-printed .p_div_user_img {
    height: 150px !important;
    width: 130px !important;
  }
  .to-be-printed .p_div_user_img {
    margin-bottom: 60px !important;
    margin-top: 60px !important;
  }
  .to-be-printed .main_cont {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }

  .to-be-printed .main_cont .text-written p,
  .to-be-printed .footer_to_be_print p,
  .to-be-printed .main_cont .text-written .e_text {
    font-size: 1.5rem !important;
  }

  .to-be-printed .t_text {
    font-size: 1.3rem !important;
  }

  .to-be-printed .footer_to_be_print {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100vw;
  }

  .to-be-printed .footer_to_be_print .last {
    margin-top: 20px !important;
  }

  .to-be-printed .footer_to_be_print .last img {
    height: 90px !important;
    margin-top: -40px !important;
  }

  @page {
    size: A4;
    margin: 0mm 4.5mm 0mm 0mm;
  }
}
