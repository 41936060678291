.Customer {
  background-color: #edf5fb;
}

.icueMail {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #3A3285;
}

.icueUserName {
  font-family: Mulish;
  font-style: normal;
  font-weight: 300;
  /* font-size: 21px; */
  /* line-height: 20px; */
  color: #252733
}

.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  line-height: 16px;
  font-family: 'Inter';
  font-size: 12px;
}

.multisteps-form__progress-btn {
  position: relative;
  background-color: white;
  border: 0;
}

@media (min-width: 500px) {
  .multisteps-form__progress-btn {
    text-indent: 0;
  }
}

.multisteps-form__progress-btn:before {
  position: absolute;
  content: '';
  top: 0px;
  left: 88%;
  border-width: 17px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb white;
  z-index: 1;
}

.multisteps-form__progress-btn:last-child:before {
  position: absolute;
  content: '';
  top: 0px;
  left: -0.4%;
  border-width: 17px 7px;
  border-style: solid;
  border-color: white white white #edf5fb;
  z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  /* color: #007bff; */
  background-color: #3a3285;
  color: #fff;
}

.multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
  position: absolute;
  content: '';
  top: 3px;
  left: 100%;
  border-width: 13.7px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb #3a3285;
}

.multisteps-form__progress-btn.js-active:last-child:before {
  position: absolute;
  content: '';
  top: 3.1px;
  left: 0.2%;
  border-width: 13px 6px;
  border-style: solid;
  border-color: #3a3285 #3a3285 #3a3285 #edf5fb;
  z-index: 1;
}

.afterBtn {
  position: relative;
  background-color: grey;
  color: white;
}

.afterBtn::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 88%;
  border-width: 16.6px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb grey;
  z-index: 1;
}

.beforeBtn {
  background-color: white;
  ;
}

.multisteps-form__progress-btn:visited {
  background-color: gray !important;
}

.multisteps-form__form {
  position: relative;
}

.multisteps-form__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.multisteps-form__panel.js-active {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.multisteps-form__panel[data-animation="scaleOut"] {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.multisteps-form__panel[data-animation="scaleOut"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.multisteps-form__panel[data-animation="slideHorz"] {
  left: 50px;
}

.multisteps-form__panel[data-animation="slideHorz"].js-active {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 1.13, 0.38, 1.43);
  transition-delay: 0s;
  left: 0;
}

.multisteps-form__panel[data-animation="slideVert"] {
  top: 30px;
}

.multisteps-form__panel[data-animation="slideVert"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  top: 0;
}

.multisteps-form__panel[data-animation="fadeIn"].js-active {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.multisteps-form__panel[data-animation="scaleIn"] {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Check box */
.customcheck {
  position: relative;
}

.customcheck input {
  display: none;
}

.customcheck input~.checkmark {
  background: #ee0b0b;
  width: 25px;
  display: inline-block;
  position: relative;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.customcheck input~.checkmark:after,
.customcheck input~.checkmark:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background: #fff;
  left: 12px;
  top: 4px;
}

.customcheck input~.checkmark:after {
  transform: rotate(-45deg);
  z-index: 1;
}

.customcheck input~.checkmark:before {
  transform: rotate(45deg);
  z-index: 1;
}

.customcheck input:checked~.checkmark {
  background: #0093dd;
  width: 25px;
  display: inline-block;
  position: relative;
  height: 25px;
  border-radius: 50%;
}

.customcheck input:checked~.checkmark:after {
  display: none;
}

.customcheck input:checked~.checkmark:before {
  background: none;
  border: 2px solid #fff;
  width: 6px;
  top: 2px;
  left: 9px;
  border-top: 0;
  border-left: 0;
  height: 13px;
  top: 2px;
}

.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
  position: relative;
  background-color: white;
  border: 0;
}

@media (min-width: 500px) {
  .multisteps-form__progress-btn {
    text-indent: 0;
  }
}

.multisteps-form__progress-btn:before {
  position: absolute;
  content: '';
  top: 0px;
  left: 88%;
  border-width: 17px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb white;
  z-index: 1;
}

.multisteps-form__progress-btn:last-child:before {
  position: absolute;
  content: '';
  top: 0px;
  left: -0.4%;
  border-width: 17px 7px;
  border-style: solid;
  border-color: white white white #edf5fb;
  z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  /* color: #007bff; */
  background-color: #3a3285;
  color: #fff;
}

.multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
  position: absolute;
  content: '';
  top: 3px;
  left: 100%;
  border-width: 13.7px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb #3a3285;
}

.multisteps-form__progress-btn.js-active:last-child:before {
  position: absolute;
  content: '';
  top: 3.1px;
  left: 0.2%;
  border-width: 13px 6px;
  border-style: solid;
  border-color: #3a3285 #3a3285 #3a3285 #edf5fb;
  z-index: 1;
}

.afterBtn {
  position: relative;
  background-color: grey;
  color: white;
}

.afterBtn::before {
  position: absolute;
  content: '';
  top: 0px;
  left: 88%;
  border-width: 16.6px 10px;
  border-style: solid;
  border-color: #edf5fb #edf5fb #edf5fb grey;
  z-index: 1;
}

.beforeBtn {
  background-color: white;
  ;
}

.multisteps-form__progress-btn:visited {
  background-color: gray !important;
}

.multisteps-form__form {
  position: relative;
}

.multisteps-form__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.multisteps-form__panel.js-active {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.multisteps-form__panel[data-animation="scaleOut"] {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.multisteps-form__panel[data-animation="scaleOut"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.multisteps-form__panel[data-animation="slideHorz"] {
  left: 50px;
}

.multisteps-form__panel[data-animation="slideHorz"].js-active {
  transition-property: all;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.2, 1.13, 0.38, 1.43);
  transition-delay: 0s;
  left: 0;
}

.multisteps-form__panel[data-animation="slideVert"] {
  top: 30px;
}

.multisteps-form__panel[data-animation="slideVert"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  top: 0;
}

.multisteps-form__panel[data-animation="fadeIn"].js-active {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0s;
}

.multisteps-form__panel[data-animation="scaleIn"] {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Check box */
.customcheck {
  position: relative;
}

.customcheck input {
  display: none;
}

.customcheck input~.checkmark {
  background: #ee0b0b;
  width: 25px;
  display: inline-block;
  position: relative;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.customcheck input~.checkmark:after,
.customcheck input~.checkmark:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background: #fff;
  left: 12px;
  top: 4px;
}

.customcheck input~.checkmark:after {
  transform: rotate(-45deg);
  z-index: 1;
}

.customcheck input~.checkmark:before {
  transform: rotate(45deg);
  z-index: 1;
}

.customcheck input:checked~.checkmark {
  background: #0093dd;
  width: 25px;
  display: inline-block;
  position: relative;
  height: 25px;
  border-radius: 50%;
}

.customcheck input:checked~.checkmark:after {
  display: none;
}

.customcheck input:checked~.checkmark:before {
  background: none;
  border: 2px solid #fff;
  width: 6px;
  top: 2px;
  left: 9px;
  border-top: 0;
  border-left: 0;
  height: 13px;
  top: 2px;
}


.shape-left {
  clip-path: polygon(75% 0%, 100% 50%, 74% 100%, 0% 100%, 0 52%, 0% 0%);
  background-color: white;
  width: 238px;
  height: 56px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;

}

.shape {
  clip-path: polygon(75% 0%, 100% 50%, 74% 100%, 0% 100%, 10% 48%, 0% 0%);
  background-color: white;
  width: 238px;
  height: 56px;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  color: black;
}

.shape-right {
  clip-path: polygon(75% 0%, 75% 51%, 74% 100%, 0% 100%, 10% 48%, 0% 0%);
  background-color: white;
  width: 238px;
  height: 56px;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
}
.highlight {
  background-color: #fff34d;
  -moz-border-radius: 5px; /* FF1+ */
  -webkit-border-radius: 5px; /* Saf3-4 */
  border-radius: 5px; /* Opera 10.5, IE 9, Saf5, Chrome */
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* FF3.5+ */
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Saf3.0+, Chrome */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7); /* Opera 10.5+, IE 9.0 */
}

.highlight {
  padding:1px 4px;
  margin:0 -4px;
}
.icueCompPlansList:hover{
  border: 2px solid #109cf1; 
}